.contact-container {
    display: flex;
    gap: 20px; 
    
   background-color: rgb(255, 251, 251);
  }
  .contacts{
    display: flex;
    gap: 20px; 
    margin: 50px;
    flex: 100%;
  }
  
  .company-details {
    flex: 0 0 65%; 
    padding: 20px;
    background-color: white;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  .company-details h1{
    font-size: 25px;
    font-weight: 300;
    margin-left: 20px;
    color: rgb(41, 41, 41);
    font-family: Poppins, sans-serif;
     font-style: normal;
     line-height: 30px;
  }
  .company-details h3{
    font-size: 20px;
    font-weight: 500;
    margin-left: 20px;
    color: #fc2722;
    line-height: 24px;
    font-family: Poppins, sans-serif;
    font-style: normal;
    margin-top: 10px;
  }
  .company-details p{
    font-size: 18px;
    color: rgb(33, 37, 41);
    margin-left: 20px;
    margin-right: 28px;
    line-height: 1.5rem;
    font-family: Poppins, sans-serif;
    font-style: normal;
    margin-top: 10px;
    font-weight: 400;
  }

  .button-containerr {
    display: flex;
    justify-content: flex-start;
    margin-left: 35px;
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .btn1 {
    padding: 10px 20px;
    color: #fff; 
    border: none;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
    margin-right: 10px;
    transition: background-color 0.3s ease; 
}
.btn2 {
    padding: 10px 20px;
    color: #fff; 
    border: none;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
    margin-right: 10px;
    transition: background-color 0.3s ease; 
}

.whatsapp {
    background-color: #25D366;
}

.mail-us {
    background-color: #fc2722;
}
  
  
  
  .btn1 {
    padding: 10px 20px;
   
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .btn1:hover {
    background-color: #0056b3;
  } 

  .headofc h4{
    margin-left: 20px;
    font-family: Poppins, sans-serif;
    font-weight: 700;
    font-size: 16px;
    font-style: normal;
    line-height: normal;
    margin-top: 20px;
  }
  .headofc p{
   
    font-size: 16px;
    font-family:  Poppins, sans-serif;
  }

  .customersupport{
    margin-top: 20px;
    /* margin-bottom: 30px; */
  }
  .customersupport h4{
    margin-left: 20px;
    font-family: Poppins, sans-serif;
    font-weight: 700;
    font-size: 16px;
    font-style: normal;
    line-height: normal;
  }
  .customersupport p{
  
    font-size: 15px;
    font-family:  Poppins, sans-serif;
  }


  .contact-form {
    flex: 0 0 35%; 
    padding: 20px;
    background-color: white; 
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
 
  }
  .contact-form h2{
    font-size: 26px;
    font-weight: 500;
    text-align: center;
  }
  

  .form-group {
    margin-bottom: 15px;
  }
  
  label {
    display: block;
    margin-bottom: 5px;
  }
  
  .contact-formm,
  textarea {
    width: 100%;
    padding: 4px;
    border-radius: 5px;
    border: 1px solid #ccc;
  }

  .submit-button {
    background-color: #fc2722;
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    width: 100%;
    position: relative;
    overflow: hidden;
   
}





.submit-button:hover {
    background-color: #c70300;
}








@media (max-width: 500px) {
    .contact-container {
        flex-direction: column;
        margin-left: -20px;
       
    }
    .contacts{
        flex-direction: column;
    }
    .company-details {
        width: 110%;
        padding: 20px;
        background-color: white;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
        order: 2;
      }
      .company-details h1{
        font-size: 17px;
        font-weight: 500;
        margin-left: 2px;
        color: rgb(41, 41, 41);
       
      }
      .button-containerr {
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-top: 20px;
        margin-bottom: 20px;
        margin-left: -3px;
        
      }
      .btn1 {
        padding: 10px 30px;
        color: #fff; 
        border: none;
        border-radius: 5px;
        font-size: 12px;
        cursor: pointer;
        margin-right: 10px;
        transition: background-color 0.3s ease; 
       margin-top: 10px;
    }
    .mail-us {
        padding: 9px 34px;
        color: #fff; 
        border: none;
        border-radius: 5px;
        font-size: 12px;
        cursor: pointer;
        margin-right: 10px;
        transition: background-color 0.3s ease; 
       margin-top: 10px;
    }
    .company-details h3{
        font-size: 16px;
        margin-left: 2px;
    }
    .company-details p{
        font-size: 13px;
       
        margin-left: 2px;
        margin-right: 2px;
       text-align: justify;
      }
   
  
 
    .headofc h4{
        margin-left: 2px;
        font-size: 14px;
    }
    .customersupport h4 {
        margin-left: 2px;
        font-size: 14px; /* Adjust font sizes for smaller screens */
    }
    .customersupport p {
        margin-left: 2px;
        font-size: 13px; /* Adjust font sizes for smaller screens */
    }
   
   .mail p{
    font-size: 14px;
   }
   .contact-form {
    order: 1;
    width: 110%;
   }
   .contact-form h2{
    font-size: 17px;
   }
  
}
@media screen and (min-width: 501px) and (max-width: 800px){
    .contact-container {
        flex-direction: column;
        margin-left: -20px;
       
    }
    .contacts{
        flex-direction: column;
    }
    .contact-form {
        order: 1;
    }
    .company-details {
        
        order: 2;
      }
}