.header-title h2{
    color: #1b1b1b;
    font-weight: 700;
    position: relative;
    margin-bottom: 10px;
    font-size: 40px;
   align-items: center;
   text-align: center;
   padding-top: 60px;
}
.header-title p{
    font-weight: 600;
    font-size: 16px;
    color: #6b7385;
    margin-bottom: 0;
    align-items: center;
    text-align: center;
}
.card-container {
    display: flex;
    justify-content: space-around; /* Adjust spacing between cards */
    flex-wrap: wrap; /* Allow cards to wrap to the next row */
    margin: 50px;
  }
  
  .card {
    flex: 1; /* Each card takes equal space */
    margin: 10px; /* Adjust margin between cards */
    position: relative;
    overflow: hidden;
    transition: transform 0.3s ease;
  }
 
  
  .card img {
    width: 100%;
    height: auto;
    border-radius: 5px; /* Optional: Add border radius to images */
  }
  .card-text {
    position: absolute;
    bottom: 0; /* Position the text at the bottom of the card */
    left: 0; /* Align the text to the left */
    width: 100%; /* Make the text take the full width of the card */
    background-image:#1b1b1b;
    color: #fff;
    padding: 8px 14px;
    box-sizing: border-box; /* Include padding in width calculation */
    margin-bottom: 10px;
    transition: transform 0.6s ease; /* Smooth transition effect */
  }
  .card:hover .card-text {
    transform: translateY(-35px); /* Move the card text up by 5px on hover */
  }
  
  .country-name {
    margin-left: 3%;
    font-size: 25px;
  }
  
  .second-line {
    margin-top: 1px; /* Adjust spacing between lines */
    text-align: left; /* Align h4 element to the left */
    font-size: 16px;
    font-weight: 400;
    margin-left: 3%;
    margin-bottom: 10px !important;
  }
  .second-line:hover{
    color: #fc2722;
  }

  .header-title {
    opacity: 0;
    transform: translateY(30px); /* Start off-screen below */
    transition: opacity 1.5s ease, transform 1.5s ease;
  }
  
  .card {
    opacity: 0;
    transform: translateY(50px); /* Start off-screen below */
    transition: opacity 3s ease, transform 3s ease;
  }
  
  .header-title.animate,
  .card.animate {
    opacity: 1;
    transform: translateY(0); /* Move in from below */
  }


  @media (max-width: 768px) {
    .header-title h2{
        font-size: 25px;
    }
    .header-title p{
        font-size: 14px;
    }
    .card-container {
      flex-direction: column; /* Stack cards vertically on smaller screens */
      align-items: center; /* Center cards horizontally */
    }
  
    .card {
      max-width: 100%; /* Allow cards to take full width on smaller screens */
    }
  }
  @media screen and (min-width: 769px) and (max-width: 912px){
    .card-container {
        display: flex;
        justify-content: space-around; /* Adjust spacing between cards */
        flex-wrap: wrap; /* Allow cards to wrap to the next row */
        margin: 30px;
      }
      .country-name {
        margin-left: 3%;
        font-size: 20px;
      }
      
      .second-line {
        margin-top: 1px; /* Adjust spacing between lines */
        text-align: left; /* Align h4 element to the left */
        font-size: 14px;
        font-weight: 400;
        margin-left: 3%;
        margin-bottom: 10px !important;
      }
  }