/* Marketplace.css */
.marketplace-container {
    display: flex;
    align-items: center;
    background-color: #fff8f5;
    color: #000;
    padding: 50px;
   
    max-width: 100%; /* Limit the maximum width of the container */
  }
  
  .image-container {
    flex: 2; /* Take up remaining space */
    padding-right: 20px; /* Add spacing between image and content */
    margin-right: 10px; /* Add margin to the right side of the image */
  }
  
  .image {
   width: 800px;
    height: auto;
   
  }
  
  .content {
    flex: 2; /* Take up more space than the image */
  }
  
  .content h2 {
 
    font-size: 40px;
    font-weight: 700;
    font-family: lato, sans-serif;
    color: #1b1b1b;
    margin-top: 0;
    margin-bottom: .5rem;
  }
  
  .content p {
    font-size: 18px;
    margin-top: 0; 
    color: #6b7385;
    
    font-weight: 600;
    margin-bottom: 40px;
  }

  .point-list {
    list-style-type: none; /* Remove default list style */
    padding-left: 0; /* Add padding to the left of the list */
  }
  
  .point-list li {
    position: relative;
    margin-bottom: 10px; /* Add spacing between list items */
    padding-left: 30px; /* Add padding to the left of list items for tick marks */
    color: #6b7385;
    font-size: 16px;
    max-width: 500px;
    display: flex;
    align-items: baseline;
    font-weight: 500;
    margin-bottom: 14px;
    list-style: none;
   
  }
  
  .point-list li::before {
    content: '\2713'; /* Unicode for checkmark symbol */
    font-size: 8px; /* Adjust the size of the checkmark symbol */
    color: #fff; /* Set the color of the checkmark symbol to white */
    position: absolute;
    left: 0.8%;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1; /* Ensure the checkmark symbol is on top of the background */
    font-weight: 600;
  }
  
  .point-list li::after {
    content: ''; /* Use an empty content for the tick mark background */
    display: inline-block;
    width: 16px; /* Adjust the width of the tick mark background */
    height: 16px; /* Adjust the height of the tick mark background */
    background-color: #fc2722; /* Red background color for the tick mark */
    border-radius: 50%; /* Create a circular tick mark background */
    margin-right: 8px; /* Add spacing between tick mark and text */
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    z-index: 0; /* Ensure the background is behind the checkmark symbol */
  }


  .buttons-container {
    display: flex;
   
    margin-top: 20px; /* Add margin above the buttons */
  }
  
  .button-primary,
  .button-secondary {
    padding: 10px 20px; /* Adjust button padding */
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 14px;
  }
  
  .button-primary {
    background-color: #fc2722; /* Red background for primary button */
    color: #fff;
    font-size: 15px;
    font-weight: 600;
  }
  .button-primary:hover {
    background-color: rgb(30, 30, 59); /* Red background for primary button */
    
  }
  
  .button-secondary {
    background-color: #fff; /* White background for secondary button */
    color: #fc2722; /* Red text color for secondary button */
    border: 1px solid #fc2722; /* Red border for secondary button */
    margin-left: 10px;
    font-size: 15px;
    font-weight: 600;
  }
  .button-secondary:hover {
    background-color: #fc2722;
    color: #fff;
  }

  .image-container,
.content {
  opacity: 0;
  transform: translateY(50px);
  transition: opacity 2.5s ease, transform 2.5s ease;
}

.image-container.animate,
.content.animate {
  opacity: 1;
  transform: translateY(0);
}


  @media (max-width: 460px){
    .image-container{
        display: none;
    }
    .image{
        display: none;
    }
    .marketplace-container {
        display: flex;
        align-items: center;
        background-color: #fff8f5;
        color: #000;
        padding: 50px;
       
        max-width: 100%; /* Limit the maximum width of the container */
      }
      
      .content h2 {
     
        font-size: 18px;
        font-weight: 550;
        font-family: lato, sans-serif;
        color: #1b1b1b;
        margin-top: 0;
        margin-bottom: .5rem;
        margin-left: -30px;
      }
      .content p {
       
        margin-top: 0; 
        color: #6b7385;
        font-size: 14px;
        font-weight: 600;
        margin-bottom: 40px;
        margin-left: -30px;
        right: 0;
      }
      .point-list li::before {
        content: '\2713'; /* Unicode for checkmark symbol */
        font-size: 8px; /* Adjust the size of the checkmark symbol */
        color: #fff; /* Set the color of the checkmark symbol to white */
        position: absolute;
        left: 1.5%;
        top: 50%;
        transform: translateY(-50%);
        z-index: 1; /* Ensure the checkmark symbol is on top of the background */
        font-weight: 600;
      }
      
      .point-list li::after {
        content: ''; /* Use an empty content for the tick mark background */
        display: inline-block;
        width: 16px; /* Adjust the width of the tick mark background */
        height: 16px; /* Adjust the height of the tick mark background */
        background-color: #fc2722; /* Red background color for the tick mark */
        border-radius: 50%; /* Create a circular tick mark background */
        margin-right: 8px; /* Add spacing between tick mark and text */
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        z-index: 0; /* Ensure the background is behind the checkmark symbol */
      }
      .point-list {
      margin-left: -30px;
      }
      .point-list li {
        position: relative;
        margin-bottom: 10px; /* Add spacing between list items */
        padding-left: 30px; /* Add padding to the left of list items for tick marks */
        color: #6b7385;
        font-size: 14px;
        max-width: 100%;
        display: flex;
        align-items: baseline;
        font-weight: 400;
        margin-bottom: 14px;
        list-style: none;
       
      }
      .buttons-container {
        display: flex;
        margin-left: -30px;
        margin-top: 20px; /* Add margin above the buttons */
        min-width: 130px;
      }
      
      .button-primary,
  .button-secondary {
    padding: 8px 12px; /* Adjust button padding */
    border: none;
    border-radius: 5px;
    cursor: pointer;
    
  }
  
  .button-primary {
    background-color: #fc2722; /* Red background for primary button */
    color: #fff;
    font-size: 14px;
    font-weight: 600;
  }
  .button-primary:hover {
    background-color: rgb(30, 30, 59); /* Red background for primary button */
    
  }
  
  .button-secondary {
    background-color: #fff; /* White background for secondary button */
    color: #fc2722; /* Red text color for secondary button */
    border: 1px solid #fc2722; /* Red border for secondary button */
    margin-left: 10px;
    font-size: 14px;
    font-weight: 600;
  }
  .button-secondary:hover {
    background-color: #fc2722;
    color: #fff;
  }

  }

  @media screen and (min-width: 461px) and (max-width: 768px){
    .image-container{
        display: none;
    }
    .image{
        display: none;
    }
    .marketplace-container {
        display: flex;
        align-items: center;
        background-color: #fff8f5;
        color: #000;
        padding: 50px;
       
        max-width: 100%; /* Limit the maximum width of the container */
      }
      
      .content h2 {
     
        font-size: 25px;
        font-weight: 550;
        font-family: lato, sans-serif;
        color: #1b1b1b;
        margin-top: 0;
        margin-bottom: .5rem;
        margin-left: -30px;
      }
      .content p {
       
        margin-top: 0; 
        color: #6b7385;
        font-size: 18px;
        font-weight: 600;
        margin-bottom: 40px;
        margin-left: -30px;
        right: 0;
      }
      .point-list {
        margin-left: -30px;
        }
        .buttons-container {
            display: flex;
            margin-left: -30px;
            margin-top: 20px; /* Add margin above the buttons */
       
          }
  }
  @media screen and (min-width: 769px) and (max-width: 990px){
    .image-container{
        display: none;
    }
    .image{
        display: none;
    }
    .marketplace-container {
        display: flex;
        align-items: center;
        background-color: #fff8f5;
        color: #000;
        padding: 50px;
       
        max-width: 100%; /* Limit the maximum width of the container */
      }
      
      .content h2 {
     
        font-size: 25px;
        font-weight: 550;
        font-family: lato, sans-serif;
        color: #1b1b1b;
        margin-top: 0;
        margin-bottom: .5rem;
        margin-left: -30px;
      }
      .content p {
       
        margin-top: 0; 
        color: #6b7385;
        font-size: 18px;
        font-weight: 600;
        margin-bottom: 40px;
        margin-left: -30px;
        right: 0;
      }
      .point-list {
        margin-left: -30px;
        }
        .buttons-container {
            display: flex;
            margin-left: -30px;
            margin-top: 20px; /* Add margin above the buttons */
       
          }
  }
  @media screen and (min-width: 991px) and (max-width: 1180px){
 
    
    .marketplace-container {
        display: flex;
        align-items: center;
        background-color: #fff8f5;
        color: #000;
        padding: 50px;
       
        max-width: 100%; /* Limit the maximum width of the container */
      }
      
      .image-container {
        flex: 2; /* Take up remaining space */
        padding-right: 20px; /* Add spacing between image and content */
        margin-right: 10px; /* Add margin to the right side of the image */
      }
      
      .image {
       width: 800px;
        height: auto;
       
      }
      
      .content {
        flex: 2; /* Take up more space than the image */
      }
      
      .content h2 {
     
        font-size: 26px;
        font-weight: 700;
        font-family: lato, sans-serif;
        color: #1b1b1b;
        margin-top: 0;
        margin-bottom: .5rem;
      }
      
      .content p {
        font-size: 15px;
        margin-top: 0; 
        color: #6b7385;
        
        font-weight: 600;
        margin-bottom: 40px;
      }
    
      .point-list {
        list-style-type: none; /* Remove default list style */
        padding-left: 0; /* Add padding to the left of the list */
      }
      
      .point-list li {
        position: relative;
        margin-bottom: 10px; /* Add spacing between list items */
        padding-left: 30px; /* Add padding to the left of list items for tick marks */
        color: #6b7385;
        font-size: 14px;
        max-width: 500px;
        display: flex;
        align-items: baseline;
        font-weight: 500;
        margin-bottom: 14px;
        list-style: none;
       
      }
      
      .point-list li::before {
        content: '\2713'; /* Unicode for checkmark symbol */
        font-size: 8px; /* Adjust the size of the checkmark symbol */
        color: #fff; /* Set the color of the checkmark symbol to white */
        position: absolute;
        left: 0.8%;
        top: 50%;
        transform: translateY(-50%);
        z-index: 1; /* Ensure the checkmark symbol is on top of the background */
        font-weight: 600;
      }
      
      .point-list li::after {
        content: ''; /* Use an empty content for the tick mark background */
        display: inline-block;
        width: 16px; /* Adjust the width of the tick mark background */
        height: 16px; /* Adjust the height of the tick mark background */
        background-color: #fc2722; /* Red background color for the tick mark */
        border-radius: 50%; /* Create a circular tick mark background */
        margin-right: 8px; /* Add spacing between tick mark and text */
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        z-index: 0; /* Ensure the background is behind the checkmark symbol */
      }
    
    
      .buttons-container {
        display: flex;
       
        margin-top: 20px; /* Add margin above the buttons */
      }
      
      .button-primary,
      .button-secondary {
        padding: 10px 20px; /* Adjust button padding */
        border: none;
        border-radius: 5px;
        cursor: pointer;
        font-size: 14px;
      }
      
      .button-primary {
        background-color: #fc2722; /* Red background for primary button */
        color: #fff;
        font-size: 15px;
        font-weight: 600;
      }
     
      
      .button-secondary {
        background-color: #fff; /* White background for secondary button */
        color: #fc2722; /* Red text color for secondary button */
        border: 1px solid #fc2722; /* Red border for secondary button */
        margin-left: 10px;
        font-size: 15px;
        font-weight: 600;
      }
      
  }
  