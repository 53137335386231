/* dashboard.css */

.dashboard {
    display: flex;
    height: auto; /* Adjust height as needed */
    overflow: hidden; 
  }
  
  .sidebar {
    width: 250px; /* Adjust width as needed */
    background-color: #fff; /* Sidebar background color */
    padding: 20px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1); 
    z-index: 1;
    left: -250px; 
    height: auto;
  }
  
  .sidebar ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
    margin-top: 40px;
  }
  
  /* .sidebar li {
    display: flex;
    align-items: center;
    margin-bottom: 25px;
    cursor: pointer;
    color: #000; 
    font-size: 18px;
    border-bottom: 1px solid #ccc; 
    padding-bottom: 10px;
    transition: margin-left 0.2s ease;
  
} */
  
  
  /* .sidebar li:hover {
    color: #fc2722;
    margin-left: 5px;
    
  } */
  .sidebar-item {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    cursor: pointer;
    color: #000; 
    font-size: 18px;
    border-bottom: 1px solid #ccc; 
    padding-bottom: 10px;
    transition: margin-left 0.2s ease;
  }
  
  .sidebar-item:hover {
    color: #fc2722;
    margin-left: 5px;
  }
  .sidebar-item:hover .svg-inline--fa {
    background-color: #fc2722; /* Change background color on hover */
    color: #fff; /* Change icon color on hover */
  }
  
  .sidebar-item .svg-inline--fa {
    margin-right: 10px;
    background-color: #333;
    color: #fff;
    border-radius: 50%;
    padding: 10px;
  }
  
  /* .sidebar li .svg-inline--fa {
    margin-right: 10px;
    background-color: #333;
    color: #fff;
    border-radius: 50%;
    padding: 10px;
  } */

 
  
  

  
  .bottom-nav {
    display: none;
    justify-content: space-around;
    align-items: center;
    background-color: #333;
    color: #fff;
    height: 60px;
}

.bottom-nav ul {
    display: flex;
    list-style-type: none;
}

.bottom-nav li {
    margin-right: 10px;
}


.dashboard-main-content {
  flex: 1;
  padding: 20px;
  background-color: #fff; /* Main content background color */
  color: #333; /* Main content text color */

}
.dashboard-card-container {
  display: flex;
  justify-content: space-between; /* Optional: Adjust as needed */
  margin-top: 30px;
}

.dashboard-card {
  flex-basis: calc(33.33% - 20px); /* Adjust width as needed */
  margin: 0 10px;
  padding: 30px;
  background: linear-gradient(135deg, rgba(54, 189, 120, 0.07) 0%, rgba(54, 142, 189, 0.07) 50%, rgba(189, 54, 54, 0.07) 100%);
  border-radius: 5px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1); 
  
  justify-content: center; /* Center content vertically */
  align-items: center; /* Center content horizontally */
  text-align: center;
}
.dashboard-icon-container {
  display: flex; /* Added display: flex */
  margin: auto;
  background-color: rgba(54, 189, 120, 0.07);;
  border-radius: 50%;
  width: 70px;
  height: 70px;
  margin-bottom: 10px; 
  justify-content: center; /* Center content vertically */
  align-items: center; /* Center content horizontally */
  text-align: center;
}

.dashboard-icon {
  color: rgb(54, 189, 120); /* Icon color */
  font-size: 34px; /* Adjust icon size */

}
.dashboard-icon-container-user {
  display: flex; /* Added display: flex */
  margin: auto;
  background-color: rgba(93, 142, 203, 0.07);;
  border-radius: 50%;
  width: 70px;
  height: 70px;
  margin-bottom: 10px; 
  justify-content: center; /* Center content vertically */
  align-items: center; /* Center content horizontally */
  text-align: center;
}
.dashboard-icon-user {
  color: rgb(54, 142, 189); 
  font-size: 34px; 

}
.dashboard-icon-container-expire {
  display: flex; 
  margin: auto;
  background-color: rgba(203, 93, 93, 0.07);;
  border-radius: 50%;
  width: 70px;
  height: 70px;
  margin-bottom: 10px; 
  justify-content: center; 
  align-items: center; 
    text-align: center;
}
.dashboard-icon-expire {
  color: rgb(189, 54, 54);
  font-size: 34px;

}
.dashboard-card h3{
  font-size: 20px;
}
.dashboard-card h1{
  font-size: 40px;
}

.dashboard-bar-chart {
 
  display: flex;
}
.dashgraph{ 
  text-align: center;
  font-size: 20px;
  color: #000;
  font-weight: 500;
}



@media only screen and (max-width: 768px) {
 


    .bottom-nav {
        display: flex;
        justify-content: space-around;
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100%;
        background-color: #333;
        color: #fff;
        height: 60px;
        z-index: 1000;
    }
    
    .bottom-nav ul {
        display: flex;
        list-style-type: none;
    }
    
    .bottom-nav li {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-right: 10px;
        font-size: 14px;
        cursor: pointer;
        transition: color 0.3s ease;
        padding: 10px;
    }
    
    .bottom-nav li:hover {
        color: #fc2722; /* Change text color on hover */
    }
    
    .bottom-nav li svg {
        margin-bottom: 5px; /* Adjust icon spacing */
    }

    .main-content {
        padding-bottom: 60px; /* Adjust content padding to accommodate bottom nav */
    }
    .sidebar ul {
        margin-top: 20px;
        margin-bottom: 50px;
      }
    .sidebar-item {
        display: flex;
        align-items: center;
        margin-bottom: 14px;
        cursor: pointer;
        color: #000; 
        font-size: 13px;
        border-bottom: 1px solid #ccc; 
        padding-bottom: 10px;
        transition: margin-left 0.2s ease;
      }
}