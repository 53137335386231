.change-password-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.change-password-modal-content {
  position: relative; /* Add this line */
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  width: 300px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.change-password-modal-content h2 {
  font-size: 18px;
  font-weight: 500;
  text-align: center;
}

.change-password-modal-content .form-group {
  margin-bottom: 15px;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
  color: black;
}

.form-group input {
  width: 100%;
  padding: 8px;
  box-sizing: border-box;
  border: 1px solid #dcdcdc; 
  border-radius: 4px; 
  color: #333; 
  background-color: #fff; 
}

.update-button {
  background-color: #28a745;
  color: white;
  border: none;
  padding: 10px;
  cursor: pointer;
  width: 100%;
  margin-bottom: 10px;
  border-radius: 4px;
}

.close-button {
  background-color: #dc3545;
  color: white;
  border: none;
  padding: 10px;
  cursor: pointer;
  width: 100%;
  border-radius: 4px;
}

.password-input-container {
  position: relative;
  display: flex;
  align-items: center;
}

.password-input-container input {
  flex: 1;
  padding-right: 30px;
}

.eye-icon {
  position: absolute;
  right: 10px;
  cursor: pointer;
  color: #333;
}

.update-button:hover {
  background: #0056b3;
}

.change-password-close-icon {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  color: #a4a4a4;
  font-size: 25px;
}
.change-password-close-icon:hover {
  
  color: #e82929;
 
}