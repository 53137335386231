@import url("https://fonts.googleapis.com/css2?family=Lato:wght@400;700&display=swap");
body {
  max-width: 100%;
}
.job-filter-container {
  display: flex;
  width: 100%;
  background-color: #f8f9fa;
  gap: 20px;
}

.job-filter-section {
  padding: 4px;
  box-sizing: border-box;
}
.filter-toggle-icon {
  display: none;
  position: fixed;
  bottom: 20px; /* Updated to bottom */
  right: 20px; /* Updated to right */
  background: #fff;
  color: #fc2722;
  padding: 10px;
  border-radius: 50%;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  z-index: 1000;
  font-size: 20px;
}

.first-section {
  flex: 1;
  max-width: 20%;
  margin-top: 40px;

  margin-left: 30px;
}
.first-section-job {
  padding: 10px;
  margin-left: 30px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background-color: white;
  border-radius: 8px;
}

.second-section {
  flex: 2;
  max-width: 60%;
  margin-top: 40px;
}

.third-section {
  flex: 4;
  max-width: 20%;
  margin-right: 30px;
  margin-top: 40px;
}

.filter-heading {
  border-bottom: 1px solid #c3bfbf;
  padding-bottom: 5px;
}

.filter-heading h1 {
  font-size: 17px;
  font-weight: 700;
  margin-top: 15px;
  margin-left: 5px;
}

/*first section */

.first-section h3 {
  margin-bottom: 15px;
  font-size: 16px;
  font-weight: 500;
  margin-left: 10px;
  display: flex;
  align-items: center;
  cursor: pointer;
}
h3 span {
  flex-grow: 1;
}
h3 svg {
  margin-left: 8px;
}
.filter-check {
  border-bottom: 1px solid #c3bfbf; /* Add a line under the heading */
  padding-bottom: 5px;
}

.first-section div {
  margin-bottom: 10px;
}

.first-section label {
  display: flex;
  align-items: center;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #474d6a;
  font-family: "Lato", sans-serif !important;
  margin-bottom: 20px;
  margin-left: 10px;
}

.first-section input[type="checkbox"] {
  margin-right: 10px;
}

/*experience css*/

.filter-experience {
  position: relative;
  width: 100%;
  max-width: 200px;
  margin: 20px 0;
  margin-left: 10px;
}

#experience-range {
  width: 100%;
  -webkit-appearance: none;
  appearance: none;
  height: 5px;
  background: #ddd;
  outline: none;
  opacity: 0.7;
  -webkit-transition: 0.2s;
  transition: opacity 0.2s;
}

#experience-range:hover {
  opacity: 1;
}

#experience-range::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 15px;
  height: 15px;
  background: black;
  cursor: pointer;
  border-radius: 50%;
  position: relative;
}

#experience-range::-moz-range-thumb {
  width: 15px;
  height: 15px;
  background: black;
  cursor: pointer;
  border-radius: 50%;
  position: relative;
}

.experience-value {
  position: absolute;
  top: -23px;
  margin-left: 27px;
  font-size: 14px;
  background: #c22222;
  color: white;
  padding: 2px 9px;
  border-radius: 3px;
  pointer-events: none;
  transform: translateX(-50%);
}

.filter-check-industry {
  max-height: 180px; /* Set the desired height */
  overflow-y: auto;
  border-bottom: 1px solid #c3bfbf; /* Add a line under the heading */
  padding-bottom: 5px;
}

.filter-check-industry::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for Firefox */
.filter-check-industry {
  scrollbar-width: none; /* Firefox */
}

.filter-check-country {
  max-height: 180px; /* Set the desired height */
  overflow-y: scroll;
  border-bottom: 1px solid #c3bfbf; /* Add a line under the heading */
  padding-bottom: 10px;
}

.filter-check-country::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for Firefox */
.filter-check-country {
  scrollbar-width: none; /* Firefox */
}

/* section2 */
.filter-job-heading {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  background-color: #fff;
  border-radius: 8px;
  cursor: pointer;
  margin-top: 10px;
}
.list-job {
  display: flex;
  justify-content: space-between; /* Align items with space between them */
  align-items: center;
}
.logo-image {
  max-width: 60px; /* Adjust the size as needed */
  border-radius: 50%; /* Makes the image circular */
  overflow: hidden;
}
.list-job h1 {
  font-size: 17px;
  font-weight: 700;
}

.company-name h2 {
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  color: #474d6a;
}
.job-details {
  margin-top: 18px;
  display: flex;
}
.job-details span {
  margin-left: 10px;
  margin-right: 10px;
  color: #d0d0d0;
}
.job-details i {
  margin-right: 5px;
  color: #555;
}
.all-details {
  color: #555;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 250px;
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
}
.job-eligibility {
  margin-top: 8px;
  color: #555;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 600px;
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
}
.job-eligibility i {
  margin-right: 5px;
  color: #555;
}

.apply-details-button {
  display: flex;
  justify-content: flex-end; /* Align buttons to the right */
  margin-top: 14px;
}

.job-details-button {
  margin-right: 10px; /* Adjust spacing between buttons */
  padding: 8px 16px;
  color: #c22222;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(0, 0, 0, 0.1);
  overflow: hidden; /* Hide the border initially */
  position: relative;
  transition: transform 0.3s ease; /* Add transition for transform */
}

.job-details-button:hover {
  transform: scale(1.1); /* Scale up the button on hover */
}

@keyframes pulseAnimation {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

.job-details-button:hover::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 0;
  height: 0;
  border-radius: 50%;
  transform: translate(-50%, -50%);
  animation: pulseAnimation 1s infinite; /* Apply animation on hover */
  pointer-events: none; /* Ensure the pseudo-element doesn't capture mouse events */
}
/* .job-apply-button {
  margin-right: 10px; 
  padding: 8px 26px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
} */
.job-details-button span {
  font-size: 15px;
  font-weight: 500;
}

.job-filter-button:last-child {
  margin-right: 0; /* Remove right margin from last button */
}

/* Third Section */
.job-box {
  padding: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
}
.job-box h2 {
  color: black;
  font-weight: 700;
  font-size: 17px;
  line-height: 20px;
  margin-bottom: 10px;
  word-break: break-word;
}
.job-box p {
  color: #a1a1a2;
  text-align: left;
  margin-bottom: 8px;
  display: inline-block;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  text-align: justify;
}
.company-market-logo {
  max-width: 150px;
  margin-bottom: 10px;
}

.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  gap: 8px;
  margin-bottom: 20px;
}

.pagination button {
  border: none;
  background-color: #f0f0f0;
  padding: 8px 16px;
  cursor: pointer;
  border-radius: 5px;
  font-size: 14px;
}

.pagination button.active {
  background-color: #007bff;
  color: white;
}

.pagination button:disabled {
  background-color: #e0e0e0;
  cursor: not-allowed;
}

.pagination .dots {
  padding: 8px;
}

.prev-btn, .next-btnnn {
  font-family: 'Arial', sans-serif;
  font-size: 16px; 
  font-weight: bold;
  border: none; 
  border-radius: 8px;
  padding: 10px 20px; 
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease; 
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.2);
}

.prev-btn {
  
  color: black; 
}

.prev-btn:hover {
 
  color: red;
}

.prev-btn:disabled {
  background-color: #aaa;
  color: #666; 
  cursor: not-allowed; 
}

.next-btnnn {

  color: black; 
}

.next-btnnn:hover {

  color: green; 
}

.next-btnnn:disabled {
  background-color: #ccc; 
  color: #666; 
  cursor: not-allowed; 
}

.job-filter-search-bar {
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
}

.job-filter-search-bar input {
  width: 100%;
  padding: 10px 20px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 50px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
}

.job-filter-search-bar input:focus {
  border-color: #f0674f;
  box-shadow: 0 2px 5px rgba(195, 70, 54, 0.3);
  outline: none;
}

.job-filter-search-bar input::placeholder {
  color: #888;
  font-style: italic;
}
.job-filter-apply {
  background-color: #fc2722;
  color: white;
  font-weight: bold;
  width: 112%;
  padding: 0.5rem 0.8rem;
  border-radius: 4px;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
}

.job-filter-apply:hover {
  background-color: #f53e3b;
  transform: scale(1.05); /* Slightly enlarge the button on hover */
}
@media (max-width: 400px) {
  .pagination button {
    font-size: 12px; /* Smaller font size */
    padding: 6px 10px; /* Reduced padding */
    border-radius: 4px; /* Smaller rounded corners */
  }

  /* Adjust previous and next buttons */
  .prev-btn, .next-btnnn {
    font-size: 14px; /* Reduce font size */
    padding: 8px 12px; /* Smaller padding */
    border-radius: 5px; /* Smaller rounded corners */
    box-shadow: none; /* Remove box-shadow for smaller screens */
  }

  /* Reduce gap between pagination items */
  .pagination {
    gap: 4px; /* Reduce gap between buttons */
    max-width: 100%; /* Ensure pagination doesn't overflow the screen */
  }

  /* Remove margin and adjust button width */
  .pagination button, .prev-btn, .next-btnnn {
    width: auto; /* Allow buttons to scale according to content */
    margin: 0; /* Remove additional margins */
  }

  /* Allow buttons to wrap */
  .pagination {
    flex-wrap: wrap; /* Ensure buttons wrap in case there are too many */
  }
}
@media (max-width: 768px) {
  .job-filter-container {
    background-color: rgb(255, 251, 251);
  }
  .first-section {
    display: none;
  }

  .filter-toggle-icon {
    display: block;
  }

  .first-section.visible {
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    width: 80%;
    height: 100%;
    background: #fff;
    z-index: 999;
    overflow-y: auto;
    padding: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    animation: slideInFromLeft 0.9s ease forwards;
  }
  @keyframes slideInFromLeft {
    0% {
      transform: translateX(-100%);
      opacity: 0;
    }
    100% {
      transform: translateX(0);
      opacity: 1;
    }
  }

  .first-section {
    max-width: 90%;
    margin-top: 50px;

    margin-left: 1px;
  }

  .first-section-job {
    padding: 10px;
    margin-left: 1px;
    box-shadow: none;
    background-color: none;
  }
  .filter-experience {
    position: relative;
    width: 100%;
    max-width: 170px;
    margin: 20px 0;
    margin-left: 10px;
  }

  /* section 2 */
  .second-section {
    flex: 2;
    max-width: 100%;
    margin-top: 10px;
    justify-content: center;
    align-items: center;
  }
  .logo-image {
    max-width: 26px;
  }
  .list-job h1 {
    font-size: 15px;
  }

  .company-name h2 {
    font-size: 12px;
  }
  .job-details i {
    font-size: 13px;
  }
  .all-details {
    font-size: 12px;
  }
  .job-eligibility {
    font-size: 13px;
  }
  .job-eligibility i {
    font-size: 13px;
  }
  .job-details-button {
    font-size: 13px;
    padding: 6px 12px;
  }
  .job-details-button span {
    font-size: 13px;
  }

  .third-section {
    display: none;
  }
  .pagination {
    flex-wrap: wrap; /* Ensure buttons wrap in case there are too many */
  }
  
}
@media screen and (min-width: 769px) and (max-width: 1000px) {
  .job-filter-container {
    background-color: rgb(255, 251, 251);
  }
  .first-section {
    display: none;
  }

  .filter-toggle-icon {
    display: block;
  }

  .first-section.visible {
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    width: 80%;
    height: 100%;
    background: #fff;
    z-index: 999;
    overflow-y: auto;
    padding: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    animation: slideInFromLeft 0.9s ease forwards;
  }
  @keyframes slideInFromLeft {
    0% {
      transform: translateX(-100%);
      opacity: 0;
    }
    100% {
      transform: translateX(0);
      opacity: 1;
    }
  }

  .first-section {
    max-width: 50%;
    margin-top: 50px;

    margin-left: 1px;
  }

  .first-section-job {
    padding: 10px;
    margin-left: 1px;
    box-shadow: none;
    background-color: none;
  }
  .second-section {
    flex: 2;
    max-width: 70%;
    margin-top: 10px;
    margin-left: 11px;
    justify-content: center;
    align-items: center;
  }
  .logo-image {
    max-width: 35px;
  }
  .list-job h1 {
    font-size: 16px;
  }

  .company-name h2 {
    font-size: 13px;
  }
  .job-details i {
    font-size: 13px;
  }
  .all-details {
    font-size: 13px;
  }
  .job-eligibility {
    font-size: 13px;
  }
  .job-eligibility i {
    font-size: 13px;
  }
  .job-details-button {
    font-size: 13px;
    padding: 6px 12px;
  }
  .job-details-button span {
    font-size: 13px;
  }

  .third-section {
    max-width: 30%;

    margin-top: 20px;
  }
  .job-box h2 {
    color: black;
    font-weight: 700;
    font-size: 15px;
    line-height: 20px;
    margin-bottom: 10px;
    word-break: break-word;
  }
  .job-box p {
    color: #a1a1a2;
    text-align: left;
    margin-bottom: 8px;
    display: inline-block;
    font-weight: 400;
    font-size: 13px;
    line-height: 18px;
    text-align: justify;
  }
  .company-market-logo {
    max-width: 150px;
    margin-bottom: 10px;
  }
}
@media screen and (min-width: 1001px) and (max-width: 1190px) {
  .job-filter-search-bar {
    margin-top: 10px;
  }
  .job-filter-container {
    background-color: rgb(255, 251, 251);
  }
  .first-section {
    display: none;
  }

  .filter-toggle-icon {
    display: block;
  }

  .first-section.visible {
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    width: 80%;
    height: 100%;
    background: #fff;
    z-index: 999;
    overflow-y: auto;
    padding: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    animation: slideInFromLeft 0.9s ease forwards;
  }
  @keyframes slideInFromLeft {
    0% {
      transform: translateX(-100%);
      opacity: 0;
    }
    100% {
      transform: translateX(0);
      opacity: 1;
    }
  }

  .first-section {
    max-width: 40%;
    margin-top: 50px;

    margin-left: 1px;
  }
  .first-section-job {
    padding: 10px;
    margin-left: 1px;
    box-shadow: none;
    background-color: none;
  }
  .second-section {
    flex: 2;
    max-width: 70%;
    margin-top: 10px;
    margin-left: 11px;
    justify-content: center;
    align-items: center;
  }
  .third-section {
    max-width: 30%;

    margin-top: 20px;
  }
}
