.offer-container {
    background: linear-gradient(to bottom right, #fc312d, #fd7b2d);
    height: auto; /* Adjust the height as needed */
   
    justify-content: center;
    align-items: center;
  }

  
  .offer-heading {
    color: #fff; /* White text color */
    font-size: 40px; /* Adjust the font size */
    text-align: center;
    font-weight: 700;
    margin: 0;
    
  }
  .offer h2{
    padding-top: 65px;
    
  }
 
  

  .offer .paragraph {
    font-size: 18px;
    font-weight: 600;
    text-align: center;
    justify-content: center;
    align-items: center;
    color: #fff;
    padding-bottom: 30px;
    margin-top: 10px;
   
  }
  .button-container {
    display: flex;
    justify-content: center;
    margin-top: 20px; /* Adjust margin as needed */
    padding-bottom: 65px;
  }
  
  .email-holder {
    width: 400px;
    padding: 8px;
    margin-right: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    outline: none;
  }
  


  .btn2{
    padding: 10px 28px;
    margin: 0 10px;
    background-color: #ffffff;
    color: #fff;
    
    border-radius: 5px;
    font-size: 16px;
   
    cursor: pointer;
    background-image: linear-gradient(to bottom, #000 50%, #fff 50%);
    background-size: 100% 200%;
    transition: background-position 0.4s ease;
    
  }
  .btn2:hover{
    background-position: 0 100%;
    color: #000;
  }
  .btn1::after {
    content: "\2192";
    font-family: "YourIconFont"; 
    font-size: 16px; 
    margin-left: 10px;
    vertical-align: middle; 
    font-weight: 400;
    font-style: normal;
  }
  .btn2::after {
    content: "\2192";
    font-family: "YourIconFont";
    font-size: 16px; 
    margin-left: 10px; 
    vertical-align: middle; 
    font-weight: 400;
    font-style: normal;
  }
  .offer-heading,
  .paragraph,
  .button-container {
    opacity: 0;
    transform: translateY(30px);
    transition: opacity 1s ease-in-out, transform 1s ease-in-out; /* Changed duration and added ease-in-out */
  }
  
  .offer-heading.animate,
  .paragraph.animate,
  .button-container.animate {
    opacity: 1;
    transform: translateY(0);
  }
  @media (max-width: 600px) {
    .button-container {
      flex-direction: column; /* Stack buttons vertically on small screens */
      align-items: center; /* Center buttons horizontally */
    }
  
    .email-holder {
     margin-bottom: 10px;
     width: 200px;
     justify-content: center;
     align-items: center;
     margin-left: 10px;
     padding: 10px 34px;
    }
    .btn2{
      padding: 10px 34px;
      margin: 0 10px;
      
      margin-bottom: 10px;
      border-radius: 5px;
      font-size: 16px;
     
      cursor: pointer;
      
      
    }
    .offer-heading {
      
      font-size: 25px; /* Adjust the font size */
     
      
    }
    .offer .paragraph {
      font-size: 16px;
      
     
    }
  }