/* Navbar.css */
body {
  padding-top: 60px; 
}
.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  color: #000;
  padding: 10px 20px;
  position: fixed;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  max-width: 100%;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
}

.navbar-left {
  flex: 1;
  width: 100px;
  margin-left: 40px;
  cursor: pointer;
}
.navbar-left img{
 
 max-width: 250px;
}


.navbar-center {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 3;
}

.navbar-center a {
  color: #000;
  text-decoration: none;
  margin: 0 15px;
  font-weight: 500;
  font-size: 15px;
 
}
.navbar-center a:hover{
  color: #fc2722;
}

.navbar-right {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 -20px;
  margin-right: 40px;

}
.navbar-right a{
  color: #000;
    font-size: 15px;
    font-weight: 500;
    display: flex;
    align-items: center;
}
.navbar-right a img{
  margin-right: 5px;
}
.navbar-right a:hover{
  color: #fc2722;
}

.emp-btn {
  padding: 7px 10px;
    margin: 0 25px;
    text-decoration: none;
    color: #fff;
    background-color: #fc2722;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 15px;
    font-weight: 700;
    background-image: linear-gradient(to bottom, #fc2722 50%, #000 50%);
    background-size: 100% 200%;
    transition: background-position 0.4s ease;
 
}

.btn-primary {
  background-color: #fc2722;
  color: #fff;
}
.emp-btn:hover{
  background-position: 0 100%;
}

.menu-toggle {
  display: none;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.bar {
  width: 25px;
  height: 3px;
  background-color: #fc2722;
  margin: 4px 0;
  transition: transform 0.3s ease;
}

.active.bar:nth-child(1) {
  transform: translateY(8px) rotate(45deg);
}

.active.bar:nth-child(2) {
  opacity: 0;
}

.active.bar:nth-child(3) {
  transform: translateY(-8px) rotate(-45deg);
}
.profile-nav{
  display: flex;
  position: relative;
}
.navbar-icon {
  position: relative; 
  font-size: 34px;
  margin-left: 100px;
  cursor: pointer;
  margin-right: 20px;
  color: #fe4324;
  animation: bellAnimation 0.6s infinite alternate;
}

@keyframes bellAnimation {
  from {
    transform: rotate(-10deg);
  }
  to {
    transform: rotate(10deg);
  }
}
.navbar-icon:hover {
  color: skyblue;
}
.navbar-avatar {
  width: 52px;
  height: 52px;
  border-radius: 50%;
  margin-left: 20px;
  cursor: pointer;
 
}
.notification-i {
  position: relative;
  cursor: pointer;
  margin-top: 10px;
}

.notification-count {
  position: absolute;
  top: -5px;
  right: -1px;
 
  color: #fe4324;
  font-size: 18px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.notification-box-container {
  position: absolute;
  top: 100%;
  right: 0;
  width: 300px;
  background-color: #ffffff;
  border: 1px solid #ccc;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 10;
  padding: 10px;
  border-radius: 4px;
  margin-top: 10px;
  overflow-y: auto; 
  max-height: 300px; 

}
.notification-box-container::-webkit-scrollbar {
  display: none; 
}

.notification-box {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

/* .notification-box ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
  overflow: hidden; 
} */

.notification-box ul li {
  padding: 8px 0;
  border-bottom: 1px solid #f4c7c7 !important;
  cursor: pointer;
  opacity: 0; 
  transform: translateX(100%); 
  animation: slideIn 0.7s ease forwards; 
}

.notification-box ul li:nth-child(1) {
  animation-delay: 0.2s; 
}

.notification-box ul li:nth-child(2) {
  animation-delay: 0.4s; 
}

.notification-box ul li:nth-child(3) {
  animation-delay: 0.6s; 
}
.notification-box ul li:nth-child(4) {
  animation-delay: 0.8s; 
}
.notification-box ul li:nth-child(5) {
  animation-delay: 1s; 
}

.notification-box ul li:last-child {
  border-bottom: none;
}



@keyframes slideIn {
  to {
    opacity: 1; 
    transform: translateX(0); 
  }
}





.notification-box ul li:hover {
  background-color: #f5f5f5;
}

.notification-box ul li h2{
  font-size: 15px;
}
.notification-box ul li p {
  margin: 0; 
  color: #666;
  font-size: 13px;
}

.profile-nav {
  position: relative;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-toggle {
  background: none;
  border: none;
  cursor: pointer;

}

.dropdown-menu {
  position: absolute;
  top: 110%;
  right: -30px;
  z-index: 1000;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  display: none;
  width: auto;
  height: auto;
}

.dropdown-menu ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.dropdown-menu li {
  padding: 10px;
}

.dropdown-menu li a {
  color: black;
  text-decoration: none;
  display: block;
  display: flex; 
  align-items: center; 
  white-space: nowrap; 
}



.dropdown-menu.show {
  display: block;
}
.profile-menu-icon{
  margin-right: 10px;
  font-size: 25px;
}

@media (max-width: 400px){
  .menu-toggle {
    display: flex;
  }
  .navbar {
    padding: 1px 20px;
    display: flex;
    justify-content: space-between; 
    align-items: center;
  }
  .navbar-center {
    display: none;
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    background-color: #fff;
    padding: 10px 0;
    z-index: 1000;
    flex-direction: column;
    align-items: center;
  }

  .navbar-center.show-menu {
    display: flex;
  }

  .navbar-center a {
    margin: 10px 0;
    color: #000;
  }

  .navbar-right {
    display: none;
    background-color: #fff;
    padding: 10px 0;
    z-index: 1000;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin-top: 130px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  }

  .navbar-right.show-menu {
    display: flex;
    position: absolute;
    top: 100%; 
    left: 0;
    width: 100%;
    align-items: center;
    justify-content: center;
    text-align: center;
  }

  .navbar-right a {
    margin: 10px 0;
    color: #000;
   margin-left: 30px;
  font-size: 13px;
  }
  .emp-btn {
    
      font-size: 13px;
     
   
  }

  .navbar-left {
    display: flex;
    justify-content: center; 
    align-items: center; 
    flex: 1; 
  }

  .navbar-left img {
    max-width: 240px;
  }
  .profile-nav{
    align-items: center;
    margin-left: -5px;
  
  }
  .navbar-icon {
    /* position: absolute;
    top: -170px; 
    right: -200px; 
    margin-left: 0;  */
    display: none;
  }
  .notification-box-container {
    position: absolute;
    top: 100%; 
    right: 0; 
    width: 300px; 
    background-color: #fff;
    border: 1px solid #ccc;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    z-index: 10; 
    padding: 10px;
    border-radius: 4px;
  }
  
  .notification-box ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }
  
  .notification-box ul li {
    padding: 8px 0;
    border-bottom: 1px solid #eee;
  }
  
  .notification-box ul li:last-child {
    border-bottom: none;
  }
  
  .notification-box ul li:hover {
    background-color: #f5f5f5; 
  }
  
  
  .notification-count {
  /* position: absolute;
  top: -180px; 
  right: -200px;  */
  display: none;
  }
}




@media screen and (min-width: 401px) and (max-width: 768px){
  .menu-toggle {
    display: flex;
  }
  .navbar {
    padding: 1px 20px;
    display: flex;
    justify-content: space-between; 
    align-items: center;
  }
  .navbar-center {
    display: none;
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    background-color: #fff;
    padding: 10px 0;
    z-index: 1000;
    flex-direction: column;
    align-items: center;
  }

  .navbar-center.show-menu {
    display: flex;
  }

  .navbar-center a {
    margin: 10px 0;
    color: #000;
  }

  .navbar-right {
    display: none;
    background-color: #fff;
    padding: 10px 0;
    z-index: 1000;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin-top: 130px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  }

  .navbar-right.show-menu {
    display: flex;
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    align-items: center;
    justify-content: center;
    text-align: center;
  }

  .navbar-right a {
    margin: 10px 0;
    color: #000;
   margin-left: 30px;
  
  }

  .navbar-left {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
  }

  .navbar-left img {
    max-width: 240px;
  }
  .profile-nav{
    align-items: center;
    margin-left: -5px;
  
  }
  .navbar-icon {
    /* position: absolute;
    top: -170px; 
    right: -200px; 
    margin-left: 0;  */
    display: none;
  }
  .notification-box-container {
    position: absolute;
    top: 100%; 
    right: 0; 
    width: 300px; 
    background-color: #fff;
    border: 1px solid #ccc;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    z-index: 10; 
    padding: 10px;
    border-radius: 4px;
  }
  
  .notification-box ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }
  
  .notification-box ul li {
    padding: 8px 0;
    border-bottom: 1px solid #eee;
  }
  
  .notification-box ul li:last-child {
    border-bottom: none;
  }
  
  .notification-box ul li:hover {
    background-color: #f5f5f5; 
  }
  
  
  .notification-count {
  /* position: absolute;
  top: -180px; 
  right: -200px;  */
  display: none;
  }
}
@media screen and (min-width: 769px) and (max-width: 850px){
  
  .menu-toggle {
    display: flex;
  }

  .navbar-center {
    display: none;
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    background-color: #fff;
    padding: 10px 0;
    z-index: 1000;
    flex-direction: column;
    align-items: center;
  }

  .navbar-center.show-menu {
    display: flex;
  }

  .navbar-center a {
    margin: 10px 0;
   color: #000;
  }

  .navbar-right {
    display: none;
  }
  /* .navbar-left {
    display: flex;
    justify-content: center; 
    align-items: center; 
    
  } */
  .navbar-right {
    display: block;
  }
  .navbar-right {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 -20px;
    margin-right: -17px;
  
  }
  .navbar-right a{
    color: #000;
      font-size: 13px;
      font-weight: 500;
      display: flex;
      align-items: center;
  }
  .navbar-right a img{
    margin-right: 5px;
  }
  
  .emp-btn {
    padding: 7px 10px;
      margin: 0 25px;
      text-decoration: none;
      color: #fff;
      background-color: #fc2722;
      border: none;
      border-radius: 5px;
      cursor: pointer;
      font-size: 15px;
      font-weight: 700;
      background-image: linear-gradient(to bottom, #fc2722 50%, #000 50%);
      background-size: 100% 200%;
      transition: background-position 0.4s ease;
   
  }
  
  .btn-primary {
    background-color: #fc2722;
    color: #fff;
  }


  .navbar-left img {
    max-width: 180px;
    
  }
  .dropdown-menu {
  
    right: 0;
    
  }

}

@media screen and (min-width: 851px) and (max-width: 1000px){
  .navbar-left {
    flex: 1;
    width: 100px;
    margin-left: 20px;
  }
  .navbar-left img{
   
   max-width: 150px;
  }
  
  
  .navbar-center {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 2;
  }
  
  .navbar-center a {
    color: #000;
    text-decoration: none;
    margin: 0 11px;
    font-weight: 500;
    font-size: 13px;
   
  }
  
  .navbar-right {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 -20px;
    margin-right: 2px;
 
  }
  .navbar-right a{
    color: #000;
      font-size: 13px;
      font-weight: 500;
      display: flex;
      align-items: center;
  }
  .navbar-right a img{
    margin-right: 5px;
    
  }
  
  .btn {
    padding: 6px 8px;
      margin: 0 25px;
      text-decoration: none;
      color: #fff;
      background-color: #fc2722;
      border: none;
      border-radius: 5px;
      cursor: pointer;
      font-size: 13px;
      font-weight: 700;
      background-image: linear-gradient(to bottom, #fc2722 50%, #000 50%);
      background-size: 100% 200%;
      transition: background-position 0.4s ease;
   
  }
  
  .btn-primary {
    background-color: #fc2722;
    color: #fff;
  }
  .btn:hover{
    background-position: 0 100%;
  }
  .dropdown-menu {
  
    right: -15px;
    
  }
}
@media screen and (min-width: 1001px) and (max-width: 1260px){
  .navbar-left {
    flex: 1;
    width: 100px;
    margin-left: 20px;
  }
  .navbar-left img{
   
   max-width: 200px;
  }
  
  
  .navbar-center {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 2;
  }
  
  .navbar-center a {
    color: #000;
    text-decoration: none;
    margin: 0 11px;
    font-weight: 500;
    font-size: 15px;
   
  }
  
  .navbar-right {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 -20px;
    margin-right: 2px;
 
  }
  .navbar-right a{
    color: #000;
      font-size: 15px;
      font-weight: 500;
      display: flex;
      align-items: center;
  }
  .navbar-right a img{
    margin-right: 5px;
    
  }
  
  .btn {
    padding: 6px 8px;
      margin: 0 25px;
      text-decoration: none;
      color: #fff;
      background-color: #fc2722;
      border: none;
      border-radius: 5px;
      cursor: pointer;
      font-size: 15px;
      font-weight: 700;
      background-image: linear-gradient(to bottom, #fc2722 50%, #000 50%);
      background-size: 100% 200%;
      transition: background-position 0.4s ease;
   
  }
  
  .btn-primary {
    background-color: #fc2722;
    color: #fff;
  }
  .btn:hover{
    background-position: 0 100%;
  }
  .dropdown-menu {
  
    right: -15px;
    
  }
}
