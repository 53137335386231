.hero-containerrr {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  
}


.content-containerrr {
  flex: 1;
  padding-right: 20px;
  padding-left: 20px;
}
.content-containerrr h1 {
  font-size: 59px;
  font-weight: 400 !important;
  font-family: 'Acumin Pro', sans-serif !important;
  position: relative;
  overflow: hidden;
  line-height: 1.2;
}
.content-containerrr h1 span {
  background: linear-gradient(to right, #f03716, #f45337, #ee712e);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;  
  display: inline-block;
  position: relative;
  opacity: 0;
  animation: slideIn 3s ease-out forwards;
}

@keyframes slideIn {
  from {
    transform: translateX(-100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

.content-containerrr p{
  font-size: 16px;
  margin-bottom: 35px;
  font-family: 'Acumin Pro', sans-serif;
  line-height: 2rem;
  margin-right: 50px;
  margin-left:20px ;
  text-align: justify;
  font-weight: 500;
  margin-top: 10px;
}
.image-containerrr {
  flex: 1;
  text-align: right;
}

.hero-image {
  max-width: 100%;
  height: auto; 
  display: block; 
  opacity: 2;
}

.herobtnnn {
  display: flex;
  gap: 15px;
  margin-left: 18px;
}

.hero-button,
.hero-button1 {
  padding: 12px 24px;
  border: none;
  color: #ffffff;
  font-size: 18px;
  letter-spacing: 1px;
  cursor: pointer;
  background: linear-gradient(90deg, #fe4324, #f45337); /* Red-orange gradient */
  border-radius: 50px;
  position: relative;
  overflow: hidden;
  z-index: 1;
  transition: color 1.4s ease;
}

.hero-button::before,
.hero-button1::before {
  content: '';
  position: absolute;
  top: 0;
  left: -100%;
  width: 300%;
  height: 100%;
  background: linear-gradient(90deg, rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 0));
  transition: 1.4s;
  z-index: -1;
}

.hero-button:hover::before,
.hero-button1:hover::before {
  left: 100%;
}

.hero-button:hover,
.hero-button1:hover {
  color: #ffffff; 
}

.hero-button1 {
  background: linear-gradient(90deg, #f45337, #ee712e); 
}

.hero-button::after,
.hero-button1::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50px;
  border: 2px solid transparent;
  background: linear-gradient(90deg, #fe4324, #ee712e); 
  z-index: -2;
  filter: blur(10px);
  opacity: 0;
  transition: opacity 1.4s ease-in-out;
}

.hero-button:hover::after,
.hero-button1:hover::after {
  opacity: 1;
}





@media (max-width: 400px) {
  .hero-containerrr{
    flex-direction: column;
  }
  .content-containerrr {
      padding: 15px;
  }
  .content-containerrr h1 {
      font-size: 1.8rem;
      margin-left: -20px;
  }
  .content-containerrr p {
      font-size: 1rem;
      margin-right: -25px;
      margin-left: -20px;
      text-align: justify;
      margin-top: 10px;
  }
  .herobtnnn {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 100%;
    margin: 0 auto;
  }
  .hero-button {
    padding: 13px 35px;
    background-color:black;
    color: #fff;
    border: none;
    border-radius: 10px;
    cursor: pointer;
    font-size: 14px;
  }
  .hero-button1 {
    padding: 13px 25px;
    background-color:white;
    color:white;
    border: none;
    border-radius: 10px;
    cursor: pointer;
    
    font-size: 14px;
    margin-left:20px ;
  }
    .hero-image {
      max-width: 100%;
  }
}
@media (min-width: 401px) and (max-width: 480px){
  .hero-containerrr{
    flex-direction: column;
  }
  .content-containerrr {
      padding: 15px;
  }
  .content-containerrr h1 {
      font-size: 2.1rem;
      margin-left: -20px;
  }
  .content-containerrr p {
      font-size: 1rem;
      margin-right: -25px;
      margin-left: -20px;
      text-align: justify;
      margin-top: 10px;
  }
  .herobtnnn {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 100%;
    margin: 0 auto;
  }
  .hero-button {
    padding: 13px 35px;
    background-color:black;
    color: #fff;
    border: none;
    border-radius: 10px;
    cursor: pointer;
    font-size: 16px;
  }
  .hero-button1 {
    padding: 13px 25px;
    background-color:white;
    color:white;
    border: none;
    border-radius: 10px;
    cursor: pointer;
    
    font-size: 16px;
    margin-left:20px ;
  }
    .hero-image {
      max-width: 100%;
  }
}
@media (min-width: 481px) and (max-width: 768px) {
  .hero-containerrr{
    flex-direction: column;
  }
  .content-containerrr {
      padding: 15px;
  }
  .content-containerrr h1 {
      font-size: 2rem;
      margin-left: -20px;
  }
  .content-containerrr p {
      font-size: 1rem;
      margin-right: -25px;
      margin-left: -20px;
      text-align: justify;
      margin-top: 10px;
  }
  .herobtnnn {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 100%;
    margin: 0 auto;
  }
  .hero-button {
    padding: 13px 35px;
    background-color:black;
    color: #fff;
    border: none;
    border-radius: 10px;
    cursor: pointer;
    font-size: 16px;
  }
  .hero-button1 {
    padding: 13px 25px;
    background-color:white;
    color:white;
    border: none;
    border-radius: 10px;
    cursor: pointer;
    
    font-size: 16px;
    margin-left:20px ;
  }
    .hero-image {
      max-width: 100%;
  }
}
@media (min-width: 769px) and (max-width: 1000px) {
.content-containerrr h1{
  font-size:38px;
  font-weight: 400;
  font-family: 'Acumin Pro', sans-serif;
}
.content-containerrr p{
  font-size: 15px;
  margin-bottom: 35px;
  font-family: 'Acumin Pro', sans-serif;
  line-height: 2rem;
  margin-right: 20px;
  margin-left: 2px ;
  text-align: justify;
}
.herobtnnn{
  margin-left:2px ;
}
.hero-button {
  padding: 9px 35px;
  background-color:black;
  color: #fff;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  font-size: 17px;
}
.hero-button1 {
  padding: 9px 17px;
  background-color:white;
  color:white;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  margin-left: 20px;
  font-size: 17px;
  margin-left:20px ;
}
}
@media (min-width: 1000px) and (max-width: 1125px) {
.content-containerrr h1{
  font-size:60px;
  font-weight: 400;
  font-family: 'Acumin Pro', sans-serif;
}
.content-containerrr p{
  font-size: 15px;
  margin-bottom: 35px;
  font-family: 'Acumin Pro', sans-serif;
  line-height: 2rem;
  margin-right: 20px;
  margin-left: 2px ;
  text-align: justify;
}
.herobtnnn{
  margin-left:2px ;
}
.hero-button {
  padding: 9px 35px;
  background-color:black;
  color: #fff;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  font-size: 17px;
}
.hero-button1 {
  padding: 9px 17px;
  background-color:white;
  color:white;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  margin-left: 20px;
  font-size: 17px;
  margin-left:20px ;
}
}
@media (min-width: 1126px) and (max-width: 1205px){
.content-containerrr p{
  text-align: justify;
}
}









