/* loader.css */
.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Adjust as needed */
 
}

.loader-img {
  width: 80%; /* Adjust the width */
  height: 100%; /* Adjust the height */
  padding-top: 30px;
}
@media (max-width: 768px) {
  .loader-img {
    width: 100%; /* Adjust the width for smaller screens */
    height: 55%;
  }
  .loader-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    margin-top: -70px;
  }
}