

.profile-section {
   display: flex;
    align-items: center;
    background-color: #f9f9f9;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    margin-top: 50px;
}

.profile-image {
    position: relative;
    width: 150px;
    height: 150px;
    margin-bottom: 20px;
    overflow: hidden; 
    border-radius: 5%;
  }
  
  .profile-image img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    object-fit: cover;
  }

.profile-image img:hover {
    border-color: #67a2eb;
}

.profile-details {
    text-align: left;
    padding: 20px;
    width: 80%;
}

.profile-details p {
    margin: 5px 0;
    font-size: 16px;
 
}

.change-password-btn {
    padding: 8px 16px;
    background-color: #67a2eb;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease-in-out;
    margin-top: 20px;
}

.change-password-btn:hover {
    background-color: #478ad1;
}
.edit-icon {
    position: absolute;
    bottom: 10px;
    right: 10px;
    background: white;
    border-radius: 50%;
    padding: 5px;
    cursor: pointer;
    font-size: 20px;
    color: #000;
    border: 1px solid #ccc;
    transition: background 0.3s, color 0.3s;
  }
  
  .edit-icon:hover {
    background: #f0f0f0;
    color: #007bff;
  }
  .column {
    display: flex;
    flex-direction: column;
    flex-basis: 50%;
   padding: 20px;
  }
  
  /* .form-group {
    margin-bottom: 20px;
    margin-left: 100px;
  } */
  
  label {
    font-weight: bold;
    margin-bottom: 5px;
  }
  
  .profile-details input[type="text"],
  .profile-details input[type="email"],
  .profile-details input[type="tel"] {
    width: 100%;
    padding: 8px;
    border-radius: 5px;
    border: 1px solid #ccc;
    box-sizing: border-box;
    outline: none;
  }
  
  .profile-details input[type="text"]:read-only,
  .profile-details input[type="email"]:read-only,
  .profile-details input[type="tel"]:read-only {
    background-color: #f0f0f0;
    cursor: not-allowed;
  }