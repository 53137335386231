.postjob-main-content {
    flex: 1;
    padding: 30px;
   background-color: rgb(255, 251, 251);;
    margin: 0;
}
.job-posting-form {
    max-width: 900px;
    margin: 0 auto;
    padding: 20px;
   
  }
  
  .job-posting-form h2 {
    text-align: center;
    margin-bottom: 20px;
    font-size: 28px;
    font-weight: bold;
  }
  
  .job-posting-form .form-group {
    margin-bottom: 15px;
  }
  
  .job-posting-form .form-group label {
    display: block;
    margin-bottom: 5px;
  }
  
  .job-posting-form .form-group .row {
    display: flex;
    gap: 20px;
  }
  
  .job-posting-form .form-group .col {
    flex: 1;
  }
  
  .job-posting-form .form-group input,
  .job-posting-form .form-group select,
  .job-posting-form .form-group textarea {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
  
  }
  
  .job-posting-form .form-group textarea {
    resize: vertical;
    height: 100px;
  }
  
  .job-posting-form .form-group button {
    width: 100%;
    padding: 10px;
    background-color: #007BFF;
    border: none;
    border-radius: 5px;
    color: white;
    font-size: 16px;
  }
  
  .job-posting-form .form-group button:hover {
    background-color: #0056b3;
  }


 
  
  .form-group input[type="file"] {
    display: block;
    margin-top: 5px;
    padding: 5px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #f9f9f9;
  }
  
  .form-group input[type="file"]:hover {
    background-color: #e0e0e0;
  }
  
  .form-group input[type="file"]:focus {
    outline: none;
    border-color: #007bff;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
  }
  
  /* Optional: Style the file input text to be more readable */
  .form-group input[type="file"]::-webkit-file-upload-button {
    font-weight: normal;
    
    border: 1px solid #007bff;
    border-radius: 5px;
    padding: 5px 10px;
    background-color: white;
    cursor: pointer;
  }
  
  .form-group input[type="file"]::-webkit-file-upload-button:hover {
    background-color: #007bff;
    color: white;
  }
  .image-preview {
    max-width: 10%;
    height: auto;
    margin-top: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  .industry-dropdown-container {
    max-height: 20px; /* Set a max height for the dropdown container */
    overflow-y: auto; /* Enable vertical scrolling */
  }

  .image-preview-container {
    position: relative;
    display: inline-block;
  }
  
  .remove-icon {
    position: absolute;
    top: 0;
    right: 0;
    cursor: pointer;
    background-color: red;
    color: white;
    border-radius: 50%;
    padding: 5px;
  }
  
  .image-preview {
    width: 100px; /* Adjust size as needed */
    height: auto;
  }
  