/* About.css */
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@700&display=swap');

.about-container {
  background-image: url('/src/COMPONENTS/about/Frame.png'); /* Replace with the path to your background image */
  background-size: cover;
  background-position: center;
  height: 50vh;
 
  display: flex;
  align-items: center;
}

.about-container h1 {
  font-size: 3em;
  margin: 0;
  text-align: left;
  color: white;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  margin-left: 50px;
  font-family: 'Roboto', sans-serif;
  position: relative;
  animation: fadeInText 1.5s ease-in-out;
}
.about-container h1::after {
    content: "";
    position: absolute;
    left: 0;
    bottom: -1px; /* Adjust the distance from the text */
    width: 100%; /* Make the line match the width of the text */
    height: 5px;
    width: 40%;
    background-color: red; /* Color of the line */
  }
  


.about-container h1 span {
  display: inline-block;
  opacity: 0;
  transform: translateX(-50px);
  animation: slideIn 0.8s forwards;
}

.about-container h1 .space {
  display: inline-block;
  width: 20px; /* Adjust the width for the space */
}

@keyframes fadeInText {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
.about-content-section {
    display: flex;
    margin-top: 20px; /* Add some margin to separate from the heading */
    padding: 20px;
    background-color: rgba(255, 255, 255, 0.8); /* Slight background color for readability */
    border-radius: 8px; /* Rounded corners for better appearance */
    opacity: 0; /* Initially hidden */
    animation: fadeIn 1s forwards, slideInFromSides 1s forwards; /* Apply animations */
  }
  
  .about-content-image {
    max-width: 50%;
    height: auto;
    border-radius: 8px; /* Rounded corners for the image */
    margin-right: 20px; /* Space between the image and text */
    transform: translateX(-100%); /* Initially off-screen to the left */
    animation: slideInFromLeft 2s forwards;
  }
  
  .about-content-text {
    max-width: 50%;
    font-family: 'Roboto', sans-serif; /* Consistent font family */
    transform: translateX(100%); /* Initially off-screen to the right */
    animation: slideInFromRight 4s forwards;
  }
  
  .about-content-text h2 {
    font-size: 1.5em;
    color: #333; /* Text color */
    position: relative; /* Required for absolute positioning */
    margin-bottom: 10px; /* Bottom margin for the heading */
  }
  
  .about-content-text h2::after {
    content: "";
    position: absolute;
    left: 0;
    bottom: -5px; /* Adjust the distance from the text */
    width: 20%; /* Adjust the width of the line */
    height: 3px; /* Adjust the thickness of the line */
    background-color: red; /* Color of the line */
  }
  
  .about-content-text p {
    font-size: 1em;
    color: #6b6b6b; 
    position: relative; 
    margin-top: 20px;
    text-align: justify;
    margin-right: 10px;
    line-height: 1.9;
  }
  .about-blog-button {
    display: inline-block;
    margin-top: 20px;
    padding: 10px 20px;
    font-size: 1em;
    color: white;
    background-color: rgb(185, 38, 38);
    border: none;
    border-radius: 5px;
    cursor: pointer;
    text-transform: uppercase;
    font-family: 'Roboto', sans-serif; /* Consistent font family */
  }
  
  .about-blog-button:hover {
    background-color: darkred; /* Change color on hover */
  }
  .about-blog-button i {
    margin-left: 5px; /* Adjust the space between text and icon */
    transition: transform 0.6s ease-out; /* Smooth transition */
  }
  
  .about-blog-button:hover i {
    animation: shake 0.9s ease-in-out infinite; /* Apply animation on hover */
  }
  
  @keyframes shake {
    0%, 100% {
      transform: translateX(0);
    }
    10%,90% {
      transform: translateX(5px); /* Adjust the shaking distance */
    }
    20%, 80% {
      transform: translateX(-5px); /* Adjust the shaking distance */
    }
  }
  
  /* Keyframes for animations */
  @keyframes fadeIn {
    to {
      opacity: 1;
    }
  }
  
  @keyframes slideInFromLeft {
    to {
      transform: translateX(0);
    }
  }
  
  @keyframes slideInFromRight {
    to {
      transform: translateX(0);
    }
  }
  
  @keyframes slideInFromSides {
    to {
      opacity: 1;
    }
  }
  .about-image-grid-heading {
    text-align: center;
    margin-top: 20px;
  }
  
  .about-image-grid-heading h2 {
    position: relative;
    display: inline-block;
    font-size: 24px; /* Adjust font size */
    color: #333; /* Adjust heading color */
    font-weight: bold;
  }
  
  .about-image-grid-heading h2::after {
    content: '';
    display: block;
    width: 50%;
    height: 2px;
    background-color: red; /* Red underline color */
    position: absolute;
    bottom: -5px;
    left: 25%;
   
  }
  .about-image-grid-heading p {
    margin-top: 10px;
    font-weight: 500;
  }
  .image-grid {
    position: relative; /* Ensure relative positioning for the container */
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    margin-left: 60px;
    margin-right: 60px;
    margin-bottom: 20px;
   
  }
  
  .grid-item {
    position: relative; /* Ensure relative positioning for the grid items */
    z-index: 1; /* Ensure grid items are above the background image */
    flex: 1;
    text-align: center;
    padding: 30px;
    background-color: rgba(107, 107, 107, 0.1); /* Light transparent background color */
    margin-right: 20px; /* Adjust gap between boxes */
  }
  .grid-item:last-child {
    margin-right: 0; /* Remove margin from the last box to prevent extra space */
  }
  
  .grid-item i {
    font-size: 24px; /* Icon size */
    color: #007bff; /* Icon color */
    margin-bottom: 10px;
  }
  
  .grid-item h3 {
    font-size: 18px; /* Heading font size */
    margin-top: 10px;
    margin-bottom: 10px;
    color: #242424;
    font-weight: 500;
    font-family: 'Quicksand', sans-serif;
  }
  
  .grid-item p {
    margin-bottom: 0;
    font-size: 16px;
    color: #777;
    line-height: 24px;
  }
  .about-grid-image {
    display: block;
    margin: 0 auto; /* Center the image horizontally */
    max-width: 100%; /* Ensure images are responsive */
    height: auto;
    margin-bottom: 10px; /* Adjust gap between image and content */
  }


  .about-section2 {
    background-size: cover;
    background-position: center;
    padding: 80px 20px;
    text-align: center;
    color: #fff;
    margin-bottom: 20px;
  }
  
  .about-section2-content {
    max-width: 800px;
    margin: 0 auto;
  }
  
  .about-section2-content h2 {
    font-size: 2.8rem;
    font-weight: bold;
    margin-bottom: 20px;
  }
  
  .about-section2 p {
    font-size: 16px;
    line-height: 1.6;
    margin-bottom: 30px;
  }
  
  .about-section2-buttons {
    display: flex;
    justify-content: center;
    gap: 20px;
  }
  
  .about-section2-button {
    
    color: #fff;
    padding: 13px 30px;
   
 
    cursor: pointer;
    transition: all 0.3s ease;
  }
  

  
  .about-read-more-button {
    position: relative;
    overflow: hidden;
    background-color: transparent;
    color: #fff;
    border: 1px solid #fff;
    font-size: 15px;
    font-weight: 400;
    border-radius: 4px;
    padding: 12px 24px;
    cursor: pointer;
    transition: color 0.3s, border-color 0.3s;
  }
  .about-read-more-button::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 0;
    height: 0;
    background-color: rgba(255, 255, 255, 0.2);
    transition: width 0.3s ease, height 0.3s ease, top 0.3s ease, left 0.3s ease;
    border-radius: 50%;
    z-index: -1;
    transform: translate(-50%, -50%);
  }
  
  .about-read-more-button:hover::before {
    width: 200%;
    height: 200%;
    top: -50%;
    left: -50%;
  }
  
  .about-read-more-button:hover {
    color: #fff;
    background-color: #233d7b;
    border-color: #233d7b;
    transition: background-color 1s, border-color 0.3s;
  }
  
  .about-contact-us-button {
    position: relative;
    overflow: hidden;
    background-color: #233d7b;
    color: #fff;
    border: 1px solid #233d7b;
    font-size: 15px;
    font-weight: 400;
    border-radius: 4px;
    padding: 12px 24px;
    cursor: pointer;
    transition: color 0.3s, border-color 0.3s;
  }
  
  .about-contact-us-button::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 0;
    height: 0;
    background-color: rgba(255, 255, 255, 0.2);
    transition: width 0.3s ease, height 0.3s ease, top 0.3s ease, left 0.3s ease;
    border-radius: 50%;
    z-index: -1;
    transform: translate(-50%, -50%);
  }
  
  .about-contact-us-button:hover::before {
    width: 200%;
    height: 200%;
    top: -50%;
    left: -50%;
  }
  
  .about-contact-us-button:hover {
    color: #fff;
    background-color: #415fa5;
    border-color: #415fa5;
    transition: background-color 1s, border-color 0.3s;
  }
  .about-content-with-image {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 40px;
    margin-left: 30px;
    margin-right: 30px;
  
  }
  
  .about-content-left {
    flex: 1;
    padding-right: 20px; /* Adjust spacing as needed */
  }
  
  .about-content-left h3 {
    font-size: 1.4rem;
    color: #000;
    margin-bottom: 5px;
    font-weight: bold;
    font-family: 'Arial', sans-serif; /* Example of using a common sans-serif font */
    position: relative; /* Ensure the relative positioning for the ::after pseudo-element */
  }
  
  .about-content-left h3::after {
    content: '';
    display: block;
    width: 50px; /* Adjust the width of the underline */
    height: 4px; /* Adjust the height and thickness of the underline */
    background-color: #ff4d4d; /* Red color for the underline */
    position: absolute;
    bottom: -5px; /* Adjust the position of the underline relative to the h3 text */
    left: 0;
  }
  
  .about-content-left p {
    font-size: 1.1rem;
    color: #666;
    line-height: 1.6;
    margin-bottom: 10px; /* Increased margin for better separation */
    text-align: justify;
    font-family: 'Arial', sans-serif; /* Example of using Google Font 'Roboto' */
  }
  
  .about-image-right {
    flex: 1;
    text-align: center;
  }
  
  .about-image-right img {
    max-width: 100%;
    height: auto;
    border-radius: 8px; /* Optional: Add border radius to the image */
  }
  .service-slider {
    padding: 20px;
    margin: 0 auto;
    max-width: 1200px;
    
  }
  
  .service-box {
    background-color: #ffffff;
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
    border-radius: 12px;
    text-align: center;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    box-sizing: border-box;
    margin-bottom: 30px;
    overflow: hidden;
    margin-top: 30px;
  }
  
  .service-box:hover {
    transform: scale(1.05);
    box-shadow: 0 8px 16px rgba(171, 43, 43, 0.2);
  }
  
  .service-image {
    width: 100%;
    height: auto;
    border-radius: 8px; /* Rounded corners for the image */
    margin-bottom: 15px; /* Space between image and text */
    transition: transform 0.3s ease; /* Smooth zoom-in effect */
  }
  
  .service-image:hover {
    transform: scale(1.1); /* Zoom in the image on hover */
  }
  
  .service-box h3 {
    margin-bottom: 10px;
    font-size: 20px;
    font-weight: bold;
    color: #333;
  }
  
  .service-box p {
    color: #555;
    font-size: 16px;
    line-height: 1.6;
    text-align: justify;
  }
  
  .slick-slide {
    padding: 0 15px; /* Adds horizontal space around each slide */
  }
  
  .slick-dots li button:before {
    font-size: 12px;
    color: #333;
  }
  
  .slick-dots {
    bottom: -30px; /* Moves the dots below the carousel */
  }
 /* service.css */
 .about-prev-arrow,
.about-next-arrow {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;

  color: #333; /* Dark gray for the arrow icon */
  border-radius: 50%;
  cursor: pointer;
  z-index: 1000;
  font-size: 20px;
  position: absolute;
}

.about-prev-arrow {
  top: 50%;
  left: -50px;
  transform: translateY(-50%);
}

.about-next-arrow {
  top: 50%;
  right: -50px;
  transform: translateY(-50%);
}
  
  @media (max-width: 768px) {
    .about-container h1 {
      font-size: 2em;
      margin-left: 20px;
    }
  
    .about-content-section {
      flex-direction: column;
      align-items: center;
    }
    .about-content-text p {
        font-size: 0.9em;
        color: #6b6b6b; 
        position: relative; 
        margin-top: 20px;
        text-align: justify;
        margin-right: 10px;
        line-height: 1.9;
      }
      .about-section2-content h2 {
        font-size: 1.7rem;
       
      }
      .about-section2 p {
        font-size: 15px;
        
      }
  
    .about-content-image,
    .about-content-text {
      max-width: 100%;
      margin: 10px 0;
      transform: translateX(0);
      animation: none;
    }
  
    .image-grid {
      flex-direction: column;
      margin-left: 20px;
      margin-right: 20px;
    }
  
    .grid-item {
      margin-right: 0;
      margin-bottom: 20px;
    }
  
    .grid-item:last-child {
      margin-bottom: 0;
    }
  
    .about-content-with-image {
      flex-direction: column;
      margin-left: 10px;
      margin-right: 10px;
      margin-top: 20px;
    }
  
    .about-content-left {
      padding-right: 0;
    }
  
    .about-section2-buttons {
      flex-direction: column;
      gap: 10px;
    }
    .service-box {
      margin-bottom: 15px;
    }
    .about-prev-arrow,
    .about-next-arrow {
      display: none;
    }
    .service-box {
     margin-top: 30px;
      margin-bottom: 50px;
      
    }
  }
  @media screen and (min-width: 769px) and (max-width: 1200px){
    .about-prev-arrow,
    .about-next-arrow {
      display: none;
    }
  }