.footer-container {
    display: flex;
    justify-content: space-between;
    background-color: #000;
    padding: 20px; /* Add padding to the footer container */
  }
  
  .footer-column {
    flex: -1;
    color: #fff;
    margin: 0 40px; /* Adjust margin to reduce the gap */
    margin-top: 20px;
    
    
  }

  .footer-column h3{
    margin-bottom: 20px;
    padding-bottom: 0;
    color: #fff;
    font-size: 22px;
    font-weight: 600;
}
.footer-links {
    padding: 0; /* Remove default padding */
    margin: 0; /* Remove default margin */
    list-style-type: none; /* Remove bullet points */
  }
  
  .footer-links li {
    margin-bottom: 9px; /* Adjust the gap between list items */
    color: #ced4e6;
    transition: transform 0.3s ease;
  }
  .footer-links li a{
    font-size: 15px;
    font-weight: 400;
    position: relative;
   
  }
  .footer-links li:hover {
    transform: scale(1.1); /* Zoom in by 10% */
    color: #fc2722;
  }
  .footer-containerr{
    display: flex;
    align-items: flex-start;
    margin-right: 70px;
    margin-top: 30px;
  }

 
  .footer-columnn h4{
    margin-bottom: 6px;
    padding-bottom: 0;
    color: #888;
    font-size: 15px;
    font-weight: 500;
}
.footer-linkss li{
   
    color: #fff;
  font-weight: bold;
  display: flex;
  font-size: 15px;
  }
  .footerimg{
    width: 192px;
    margin-top: 20px;
  }
 .bar1{
  width: 500%;
  margin-bottom: 30px;
  height: 1px; 
  background-color: #909191;
  margin: 16px;
  margin-left: -13px;
 }

  .copyright {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #fff;
    font-size: 16px;
    background-color: #000;
   margin-top: -3px;
  }
  .copyright-text{
    margin-left: 50px;
    margin-bottom: 38px;
  }


.social-icons {
  display: flex;
  margin-right: 60px;
  align-items: center;
 
}

.social-icons i {
  color: #000;
  margin-right: 30px;
  border-radius: 50%;
  padding: 10px; 
}


.fb-facebook {
  font-size: 18px;
  background-color: #fff;
  border-radius: 50%;
  width: 38px;
  height: 38px;
  justify-content: center;
  align-items: center;
}
.fb-facebook:hover{
  background-color:#d72121;
  color: white;
}

.fb-twt, .fb-insta, .fb-linkedin {
  background-color: #fff;
  border-radius: 50%;
} 
 .fb-linkedin:hover{
  background-color:#d72121;
  color: white;
}
 .fb-insta:hover{
  background-color:#d72121;
  color: white;
}
.fb-twt:hover{
  background-color:#d72121;
  color: white;
}
.footer-links {
  list-style: none;
  padding: 0;
}

.footer-links li {
  display: flex;
  align-items: center;
  margin: 5px 0;
}

.footer-links li .fa-caret-right {
  color: #fc2722;
  margin-right: 10px;
}

.footer-links li a {
  text-decoration: none;
}


 

  @media screen and (max-width: 399px) {
    .footer-container {
      flex-wrap: wrap; /* Allow items to wrap */
    }
  
    .footer-column {
      flex: 1 0 50%; /* Two columns in a row for smaller screens */
      margin: 0 5px; /* Adjust margin for smaller screens */
    }
  
    .footer-containerr {
      flex-direction: column; /* Stack items vertically */
      align-items: left;
      text-align: left;
    }
  
    .footerimg {
      margin-top: 10px;
    }
    .footer-containerr{
      flex-wrap: wrap;
    }
  
   
    .footer-columnn {
      flex: 1 0 50%;
      margin: 0 5px;
  }
  .footer-columnn h4{
    margin-bottom: 6px;
    padding-bottom: 0;
    color: #888;
    font-size: 12px;
    font-weight: 500;
}
.footer-linkss li{
   
    color: #fff;
  font-weight: bold;
  display: flex;
  font-size: 12px;
  }

  .footer-column h3{
    margin-top: 10px;
    margin-bottom: 10px;
    padding-bottom: 0;
    color: #fff;
    font-size: 17px;
    font-weight: 600;
}
.footer-links li {
  margin-bottom: 8px; /* Adjust the gap between list items */
  color: #ced4e6;
  transition: transform 0.3s ease;
}
  .footer-links li a{
    font-size: 14px;
    font-weight: 400;
    position: relative;
   
  }
  .sup{
    margin-left: -50px;
  }
  .copyright{
    flex-direction: column;
    margin-top: -30px;
  }
  .bar1{
    width: 120%;
    margin-bottom: 5px;
    height: 1px; 
    background-color: #909191;
    margin-left: -24px;
   }
   .copyright-text{
    font-size: 14px;
    margin-left: 1px;
    margin-bottom: 38px;
   }
   .social-icons{
    align-items: center;
    justify-content: center;
    margin-left: 85px;
    margin-top: -14px;
    margin-bottom: 30px;
   }
  
  
  }
  @media screen and (min-width: 400px) and (max-width: 600px){
    .footer-container {
      flex-wrap: wrap; /* Allow items to wrap */
    }
  
    .footer-column {
      flex: 1 0 50%; /* Two columns in a row for smaller screens */
      margin: 0 5px; /* Adjust margin for smaller screens */
    }
  
    .footer-containerr {
      flex-direction: column; /* Stack items vertically */
      align-items: left;
      text-align: left;
    }
  
    .footerimg {
      margin-top: 10px;
    }
    .footer-containerr{
      flex-wrap: wrap;
    }
  
   
    .footer-columnn {
      flex: 1 0 50%;
      margin: 0 5px;
  }



  .copyright{
    flex-direction: column;
    margin-top: -30px;
  }
  .bar1{
    width: 130%;
    margin-bottom: 5px;
    height: 1px; 
    background-color: #909191;
    margin-left: -44px;
   }
   .copyright-text{
    font-size: 16px;
    margin-left: 1px;
    margin-bottom: 38px;
   }
   .social-icons{
    align-items: center;
    justify-content: center;
    margin-left: 85px;
    margin-top: -14px;
    margin-bottom: 30px;
   }
  

  }
  @media screen and (min-width: 601px) and (max-width: 799px){
    .footer-container {
      flex-wrap: wrap;
    }
  
    .footer-column {
      flex: 1 0 50%;
      margin: 0 5px;
    }
  
    .footer-containerr {
      flex-direction: column; 
    
      
      flex-wrap: wrap;
     
    }
    .footer-columnn h4{
      margin-left: -80px;
    }
    .footer-columnn ul li{
      margin-left: -80px;
    }
   
    
  .fimg .footerimg{
    margin-left: -80px;
    width: 300px;
  }
  .fimg .footerimg img{
    max-width: 400px !important;
  }
   
    
  
   
    .footer-columnn {
      flex: 2 0 50%;
      margin: 0 100px;
  }
  .footer-columnn .sup{
    margin-left: -290px;
  }
  .copyright{
    flex-direction: column;
    margin-top: -30px;
  }
  .bar1{
    width: 170%;
    margin-bottom: 5px;
    height: 1px; 
    background-color: #909191;
    margin-left: -110px;
   }
   .copyright-text{
    font-size: 18px;
    margin-left: 1px;
    margin-bottom: 38px;
   }
   .social-icons{
    align-items: center;
    justify-content: center;
    margin-left: 85px;
    margin-top: -14px;
    margin-bottom: 10px;
   }
  
  
  }
  @media screen and (min-width: 800px) and (max-width: 1000px){
    /* .footer-container {
      flex-wrap: wrap; 
    }
  
    .footer-column {
      flex: 1 0 50%; 
      margin: 0 5px;
    }
  
    .footer-containerr {
      flex-direction: column; 
      align-items: left;
      text-align: left;
    }
  
    .footerimg {
      margin-top: 10px;
    } */
    .footer-containerr{
      display: none;
    }
  
   
    /* .footer-columnn {
      flex: 1 0 50%;
      margin: 0 5px;
  } */
  .copyright{
    flex-direction: column;
 
  }
  .bar1{
    width: 200%;
    margin-bottom: 5px;
    height: 1px; 
    background-color: #909191;
    margin-left: -180px;
   }
   .copyright-text{
    font-size: 20px;
    margin-left: 1px;
    margin-bottom: 38px;
   
   }
   .social-icons{
    align-items: center;
    justify-content: center;
    margin-left: 85px;
    margin-top: -14px;
    margin-bottom: 10px;
   }
  
  }
  @media screen and (min-width: 1001px) and (max-width: 1160px){
    .footer-containerr{
      display: none;
    }
  
   
    
  .copyright{
    flex-direction: column;
 
  }
  .bar1{
    width: 250%;
    margin-bottom: 5px;
    height: 1px; 
    background-color: #909191;
    margin-left: -260px;
   }
   .copyright-text{
    font-size: 20px;
    margin-left: 1px;
    margin-bottom: 38px;
   
   }
   .social-icons{
    align-items: center;
    justify-content: center;
    margin-left: 85px;
    margin-top: -14px;
    margin-bottom: 10px;
   }
  }


  


  @media screen and (min-width: 1161px) and (max-width: 1223px){
    
  

  .bar1{
    width: 390%;
    margin-bottom: 5px;
    height: 1px; 
    background-color: #909191;
    margin-left: -10px;
   }
   .social-icons{
    margin-left: 50px;
   }
  }

  @media screen and (min-width: 1224px) and (max-width: 1250px){
    
  

    .bar1{
      width: 410%;
      margin-bottom: 5px;
      height: 1px; 
      background-color: #909191;
      margin-left: -10px;
     }
     .social-icons{
      margin-left: 50px;
     }
    }
    
  @media screen and (min-width: 1251px) and (max-width: 1280px){
    
  

    .bar1{
      width: 420%;
      margin-bottom: 5px;
      height: 1px; 
      background-color: #909191;
      margin-left: -14px;
     }
     .social-icons{
      margin-left: 50px;
     }
    }
    @media screen and (min-width: 1360px) and (max-width: 1420px){
      .bar1{
        width: 460%;
        margin-bottom: 5px;
        height: 1px; 
        background-color: #909191;
       
       }
       .social-icons{
        margin-left: 50px;
       }
    }
    @media screen and (min-width: 1421px) and (max-width: 1500px){
      .bar1{
        width: 485%;
        margin-bottom: 5px;
        height: 1px; 
        background-color: #909191;
        margin-left: -14px;
       }
       .social-icons{
        margin-left: 30px;
       }
    }