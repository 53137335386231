/* jobDetailsModal.css */

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.modal-content {
  background-color: #8197ae;
  padding: 40px;
  border-radius: 12px;
  width: 600px;
  max-width: 90%;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.4);
  text-align: left;
  color: #fff;
  position: relative;
  max-height: 90%;
  overflow-y: auto; /* Allows vertical scrolling */
  overflow-x: hidden;
  box-sizing: border-box;
}
.modal-content * {
  box-sizing: border-box; /* Ensures all child elements include padding and border in their width and height */
  max-width: 100%; /* Prevents child elements from exceeding the modal width */
  word-wrap: break-word; /* Prevents long words from forcing horizontal scrolling */
}

/* Hide horizontal scrollbar */
.modal-content::-webkit-scrollbar {
  width: 0;
  height: 0;
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
  width: 100%;
}

.modal-header h2 {
  margin: 0;
  font-size: 2rem; /* Larger title */
  font-family: "Lato", sans-serif;
}

.company-logo {
  width: 60px; /* Larger logo */
  height: auto;
}

.modal-content h3 {
  margin: 0;
  font-size: 1.4rem; /* Slightly larger company name */
  margin-top: -20px;
  font-family: "Lato", sans-serif;
}

.modal-content p {
  margin: 10px 0;
  font-size: 1.1rem; /* Larger text */
  font-family: "Lato", sans-serif;
  text-align: justify;
}

.modal-content p strong {
  font-weight: bold; /* Bold text */
}

.apply-button {
  margin-top: 30px;
  padding: 6px 20px;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  font-size: 1.1rem;
  background-color: #9e0606; /* Blue background */
  color: #fff; /* White text color */
  transition: background-color 0.3s ease, color 0.3s ease, transform 0.3s ease;
  box-shadow: 0 4px 6px rgba(0, 118, 255, 0.3); /* Light shadow */
  outline: none; /* Remove outline on focus */
}

.apply-button:hover {
  background-color: #138004; /* Darker blue on hover */
  transform: translateY(-2px); /* Move button slightly up on hover */
}

.apply-button:active {
  transform: translateY(0); /* Reset transform on click */
  box-shadow: none; /* Remove shadow on click */
}
.close-icon {
  position: absolute;
  top: 10px;
  right: 15px;
  font-size: 1.5rem;
  color: #fff; /* White color for the icon */
  cursor: pointer;
  transition: color 0.3s ease;
}

.close-icon:hover {
  color: #f00; /* Red color on hover */
}
.input-fields {
  display: flex;
  justify-content: space-between;
  gap: 10px;
}

.input-field {
  flex: 1;
}

.input-field label {
  display: block;
  margin-bottom: 5px;
}

.input-field input {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  color: black;
}
.error-message {
  color: rgb(166, 1, 1);
  margin-top: 10px;
}
.apply-button {
  /* Regular button styles */
  background-color:  #f00;
  color: white;
  padding: 10px;
  border: none;
  cursor: pointer;
}

.apply-button.disabled,
.apply-button:disabled {
  background-color: grey;
  cursor: not-allowed;
  opacity: 0.5;
}
.filter-note h2 { 
  color: rgb(206, 8, 8);
  font-family: 'Roboto', sans-serif;
  font-style: italic;
  font-size: 0.9em;
  font-weight: bold; 
  background-color: #c0c0c0; 
  padding: 10px; 
  border-left: 5px solid #f00;
  border-right: 5px solid #f00;
  border-radius: 5px;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.1);
}

@media (max-width: 768px) {
  .modal-content {
    padding: 16px; /* Further reduced padding */

    max-height: 80%; /* Ensures modal content does not exceed the viewport height */

    overflow-y: auto; /* Enables vertical scrolling */
  }

  .modal-header h2 {
    font-size: 1.1rem; /* Further reduced font size */
  }

  .modal-header .company-logo {
    display: none;
  }

  .modal-content h3 {
    font-size: 1rem; /* Further reduced font size */
  }

  .modal-content p {
    font-size: 0.9rem; /* Further reduced font size */
    text-align: justify;
  }

  .apply-button {
    padding: 6px 12px; /* Further reduced padding */
    font-size: 0.9rem; /* Further reduced font size */
  }
  .input-fields {
    flex-direction: column;
    gap: 0;
  }

  .input-field {
    margin-bottom: 10px;
  }
}
