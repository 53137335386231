/* Careeradvice.css */

.career-section-container {
  background-image: url(./icon/careerbg.svg);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 350px; /* Set the height according to your design */
  color: white; /* Text color on top of the background image */
  text-align: center;
  padding: 50px;
}

.career-section-container h1 {
  font-size: 56px;
  font-weight: 700;
  font-family: "Inter", sans-serif;
}
.career-section-container p {
  font-size: 20px;
  font-weight: 500;
  margin-top: 20px;
}

.career-card-container {
  display: flex;
  justify-content: space-between;
  margin-bottom: 40px;
  margin: 50px;
  margin-top: -70px;
  overflow: hidden;
}

.career-card {
  background-color: #fff;
  padding: 20px;
  margin-bottom: 10px;
  margin-right: 20px;
  flex-basis: calc(25% - 20px); /* Adjust width based on your design */
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1), 0 4px 8px #ffedd5;
  position: relative;
  position: relative;
  animation: slide-in 0.5s ease-out forwards; /* Add animation */
  opacity: 0;
}
@keyframes slide-in {
  0% {
    transform: translateX(-100%); /* Start off-screen to the left */
    opacity: 0; /* Start hidden */
  }
  100% {
    transform: translateX(0); /* End at the original position */
    opacity: 1; /* Fully visible */
  }
}

/* Add a delay for each card to create a staggered effect */
.career-card:nth-child(1) {
  animation-delay: 0.2s;
}
.career-card:nth-child(2) {
  animation-delay: 0.4s;
}
.career-card:nth-child(3) {
  animation-delay: 0.8s;
}
.career-card:nth-child(4) {
  animation-delay: 1s;
}

.career-card:last-child {
  margin-right: 0;
}

.career-card h3 {
  margin-bottom: 10px;
  font-size: 19px;
  font-weight: 700;
}
.career-card p {
  text-align: justify;
  color: rgb(100 116 139);
}
.career-icon {
  background-color: rgb(255, 246, 241);
  border-radius: 8px; /* Adjust the radius value as needed */
  padding: 12px; /* Adjust the padding value as needed */
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1); /* Optional: Add a subtle shadow */
}

.qa-section {
  display: flex;
  justify-content: space-between;
  align-items: stretch; /* Stretch to cover full height */
  background-image: url(./icon/Frame.png);
  background-size: cover; /* Ensure the image covers the container */
  background-position: center;
  padding: 120px; /* Add padding for spacing */
  box-sizing: border-box; /* Include padding in width calculation */
}
.qa-content {
  display: flex;
  justify-content: space-between;
  align-items: stretch; /* Stretch to cover full height */
  width: 100%;
  margin-top: -80px;
}



.truncated-text {
  display: -webkit-box;
  -webkit-line-clamp: 3; /* Number of lines to show */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  height: calc(1.2em * 3); /* Adjust the height according to the number of lines */
  line-height: 1.2em; /* Line height */
}

.blog-card img {
  height: 100px; /* Set the desired fixed height */
  width: 100%; /* Ensure the image spans the full width */
  object-fit: cover; /* Maintain aspect ratio, cover the area */
}


/* Styles for qa-heading */
.qa-heading {
  width: 50%;
  padding: 20px; /* Add padding for spacing */
  box-sizing: border-box;
}
.qa-heading p {
  color: #fc2722;
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 10px;
  margin-left: 4px;
}
.qa-heading h3 {
  color: #000;
  font-size: 48px;
  font-weight: 600;
  line-height: 57.2px;
}

/* Styles for qa-list */
.qa-list {
  width: 50%;
  padding: 20px; /* Add padding for spacing */
  box-sizing: border-box;
}

/* Styles for qa-item */
.qa-item {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.02); /* Add box shadow for each QA item */
  border-radius: 5px; /* Add border radius for rounded corners */
  margin-bottom: 10px;
  padding: 10px; /* Add padding inside the box */
}

/* Styles for qa-item h3 (question) */
.qa-item h3 {
  margin-bottom: 5px;
  font-weight: bold;
  cursor: pointer;
  text-align: justify;
}

/* Styles for qa-item p (answer) */
.qa-item p {
  margin-right: 70px;
  text-align: justify;
}

.icon-wrapper {
  margin-left: 100px;
}
.qa-item h3.open {
  color: #fc2722; /* Change the color to red or any other color you prefer */
}

.search-bar {
  position: relative;
  display: inline-block;
  margin-top: 15px;
}

/* Style for the input field */
.search-bar input[type="text"] {
  padding: 12px 12px;

  border-radius: 8px;
  width: 400px; /* Adjust width as needed */
  background-color: #edebeb;
}

/* Style for the search icon */
.search-bar i.fa-search {
  position: absolute;
  top: 50%;
  right: 15px;
  transform: translateY(-50%);
  cursor: pointer;
  color: #fc2722;
  animation: pulse 1s infinite;
}
@keyframes pulse {
  0% {
    transform: translateY(-50%) scale(1); /* Initial state */
  }
  50% {
    transform: translateY(-50%) scale(1.2); /* Enlarged state */
  }
  100% {
    transform: translateY(-50%) scale(1); /* Final state */
  }
}

.latest-blog-section {
  padding: 40px 20px;
  text-align: center;
}

.latest-blog-content {
  max-width: 800px;
  margin: 0 auto;
}

.latest-blog-content h2 {
  font-size: 32px;
  font-weight: 700;
  margin-bottom: 10px;
}

.latest-blog-content p {
  font-size: 16px;
  line-height: 1.6;
  font-weight: 400;
  color: #000;
}

.three-cards-section {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  margin-left: 100px;
  margin-right: 100px;
  margin-bottom: 20px;
}

.blog-card {
  background-color: white;
  border-radius: 8px;
  height: auto;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  max-width: 400px;
  flex: 0 1 calc(33.33% - 10px);
 
}
.blog-head {
  padding: 20px;
}

.blog-head h3 {
  font-size: 16px;
  margin-bottom: 10px;
  font-weight: bold;
}

.blog-head p {
  font-size: 15px;
  margin-bottom: 20px;
  color: #7a7a7a;
  text-align: justify;
}

.read-more-btn {
  color: #fc2722;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-weight: bold;
}
.read-more-btn:hover {
  animation: shake 0.4s ease; /* Apply shake animation on hover */
}
@keyframes shake {
  0%,
  100% {
    transform: translateX(0);
  }
  25%,
  75% {
    transform: translateX(-10px);
  }
  50% {
    transform: translateX(10px);
  }
}

.blog-card img {
  height: auto; /* Maintains aspect ratio */
  display: block; /* Ensures proper layout */
}

/* For small screens (phones) */
@media ( max-width: 767px) {
  .career-section-container {
    height: auto; /* Let the height adjust based on content */
    padding: 30px 20px; /* Less padding on small screens */
  }
  .career-section-container h1 {
    font-size: 30px;
  }
  .career-section-container p {
    font-size: 15px;
  }
  .career-card-container {
    margin: 20px; /* Less margin on small screens */
    margin-top: 0; /* Align the container at the top */
    flex-direction: column;
    margin-top: 20px;
  }
  .career-card {
    flex-basis: 100%; /* Full width for each card */
    margin-right: 0; /* No margin on the right */
    flex-direction: column;
    margin-top: 10px;
  }
  .qa-section {
    padding: 20px 10px;
    background-image: url(./icon/careerbg2.png);
    background-size: cover; /* Ensure the image covers the container */
    background-position: center;
  }
  .qa-content {
    flex-direction: column;
    align-items: center;
    margin-top: 0;
  }
  .qa-heading,
  .qa-list {
    width: 100%;
    padding: 20px;
  }
  .qa-heading h3 {
    font-size: 23px;
    line-height: 1.8rem;
  }
  .qa-heading p {
    font-size: 18px;
    margin-left: 1px;
  }
  .search-bar input[type="text"] {
    width: 120%;
    padding: 8px 12px;
  }
  .search-bar i.fa-search {
    margin-right: -40px;
  }
  .icon-wrapper {
    margin-left: 0;
  }
  .qa-item {
    padding: 5px;
    margin-left: 7px;
    text-align: left;
  }
  .qa-item h3 {
    text-align: left;
  }
  .qa-item p {
    margin-right: 0;
    font-size: 13px;
  }
  .qa-item h3 {
    font-size: 14px;
  }
  .three-cards-section {
    margin-left: 20px;
    margin-right: 20px;
    flex-direction: column;
    margin-top: -20px;
  }

  .blog-card {
    justify-content: center;
    align-items: center;
    margin-top: 20px;
  }
  .infront-faq-right-side {
    width: 100% !important; /* Make both sections take full width */
  
    padding-right: 0;
  }
  .infront-faq-question{
    font-size: 16px !important;
  }
  .infront-faq-answer{
    font-size: 16px !important;
    text-align: justify;
  }
}

@media (min-width: 450px) and (max-width: 767px) {
  .three-cards-section {
    justify-content: center;
    flex-direction: column;
    margin-top: -20px;
    align-items: center;
  }

  .blog-card {
    justify-content: center;
    align-items: center;
    margin-top: 20px;
  }
  .infront-faq-right-side {
    width: 100% !important; /* Make both sections take full width */
  
    padding-right: 0;
  }
  .infront-faq-question{
    font-size: 16px !important;
  }
}

@media (min-width: 768px) and (max-width: 999px) {
  .career-section-container {
    height: 350px;
    padding: 40px;
  }
  .career-section-container h1 {
    font-size: 50px;
  }
  .career-section-container p {
    font-size: 18px;
  }
  .career-card-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
    margin: 50px 20px; /* Adjust margin for smaller screens */
    margin-top: -70px;
  }

  .career-card {
    margin-right: 0; /* Remove right margin as grid gap will handle spacing */
    flex-basis: auto; /* Reset flex-basis to auto for grid layout */
  }

  .qa-section {
    padding: 60px;
    flex-direction: column;
    align-items: flex-start;
  }

  .qa-content {
    flex-direction: column;
    margin-top: 0;
  }

  .qa-heading,
  .qa-list {
    width: 100%;
    padding: 10px;
  }

  .qa-heading h3 {
    font-size: 40px;
    line-height: 42px;
  }

  .qa-heading p {
    font-size: 20px;
  }

  .qa-item h3 {
    margin-bottom: 5px;
    font-weight: bold;
    cursor: pointer;
    font-size: 20px;
  }

  /* Styles for qa-item p (answer) */
  .qa-item p {
    font-size: 18px;
  }

  .icon-wrapper {
    margin-left: 210px; /* Add space between question and icon */
  }

  .icon-wrapper {
    margin-left: 0;
  }

  .search-bar input[type="text"] {
    width: 240%;
  }
  .search-bar i.fa-search {
    margin-right: -280px;
  }
  .three-cards-section {
    margin-left: 20px;
    margin-right: 20px;
  }
  .blog-card {
    flex: 0 1 calc(50% - 10px); /* Adjust to two cards per row */
    margin-left: 10px;
  }
}

@media (min-width: 1000px) and (max-width: 1150px) {
  .career-card-container {
    display: flex;
    justify-content: space-around;
    margin-bottom: 40px;
    margin: 20px;
    margin-top: -70px;
  }

  .career-card {
    background-color: #fff;
    padding: 10px;
    margin-right: 20px;
    flex-basis: calc(25% - 20px); /* Adjust width based on your design */
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1), 0 4px 8px #ffedd5;
    position: relative;
  }

  .qa-section {
    margin-top: 50px;
    justify-content: space-between;
  }

  .qa-heading {
    width: 50%;
    padding: 20px; /* Add padding for spacing */
    box-sizing: border-box;
  }
  .qa-heading p {
    color: #fc2722;
    font-size: 22px;
    font-weight: 500;
    margin-bottom: 10px;
    margin-left: 4px;
  }
  .qa-heading h3 {
    color: #000;
    font-size: 33px;
    font-weight: 600;
    line-height: 57.2px;
  }

  .qa-list {
    width: 50%;
    padding: 30px; /* Add padding for spacing */
    box-sizing: border-box;
    margin-right: -50px;
  }
  .search-bar input[type="text"] {
    width: 150%;
  }
  .search-bar i.fa-search {
    margin-right: -100px;
  }

  .qa-item {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.02);  
    border-radius: 5px; 
    margin-bottom: 10px;
    padding: 10px; 
  }

  /* Styles for qa-item h3 (question) */
  .qa-item h3 {
    margin-bottom: 5px;
    font-weight: bold;
    cursor: pointer;
    font-size: 18px;
  }

  /* Styles for qa-item p (answer) */
  .qa-item p {
    margin: 0;
  }

  .icon-wrapper {
    margin-left: 5px; /* Add space between question and icon */
  }
}

@media (min-width: 1151px) and (max-width: 1250px) {
  .qa-item {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.02); /* Add box shadow for each QA item */
    border-radius: 5px; /* Add border radius for rounded corners */
    margin-bottom: 10px;
    padding: 10px; /* Add padding inside the box */
  }

  /* Styles for qa-item h3 (question) */
  .qa-item h3 {
    margin-bottom: 5px;
    font-weight: bold;
    cursor: pointer;
    font-size: 18px;
  }

  /* Styles for qa-item p (answer) */
  .qa-item p {
    margin: 0;
  }

  .icon-wrapper {
    margin-left: 0; /* Add space between question and icon */
  }
}

@media (min-width: 1251px) and (max-width: 1305px) {
  .icon-wrapper {
    margin-left: 180px; /* Add space between question and icon */
  }
}


.infront-faq-right-side {
  width: 60%;
}
.infront-faq-item {
  margin-bottom: 25px;
}
/* Question Styling */
.infront-faq-question {
  cursor: pointer;
  padding: 10px 0;
  font-size: 20px;
  font-weight: 600;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #2C2C2C; /* Dark grey for a clean professional look */
  border-bottom: 1px solid #747474; /* Underline to separate each question */
  transition: color 0.6s ease, border-color 0.6s ease;
}
.infront-faq-question:hover {
  color: #ed6815; /* Subtle blue highlight on hover */
  border-color: #ed6815; /* Blue underline for hover effect */
}
/* Icon Styling */
.infront-faq-icon {
  transition: transform 0.6s ease;
  font-size: 1.3rem;
  color: #ed6815; /* Match the hover color */
}
.infront-faq-icon.rotate {
  transform: rotate(180deg);
}
/* Answer Styling */
.infront-faq-answer {
  padding: 0 0 16px;
  font-weight: 400;
  font-size: 18px;
  line-height: 150%;
  color: #171717;
  transition: all 0.6s ease;
  animation: fadeIn 0.6s ease-in-out;
}
/* Smooth fade-in animation for the answer */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
/* Slight hover movement for each item */
.infront-faq-item:hover .infront-faq-question {
  transform: translateY(-3px);
  transition: transform 0.5s ease;
}
