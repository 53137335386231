.blog-details {
    
    margin: 30px;
    padding: 20px;
  }
  
  .blog-header {
    text-align: center;
    margin-bottom: 20px;
    padding: 10px;
    background: linear-gradient(to bottom right, #fc312d, #fd7b2d);
    border-radius: 8px;
  }
  
  .blog-title {
    font-size: 2.5em;
    margin: 0;
    color: #fff;
    font-family: 'Georgia, serif';
    font-weight: bold;
  }
  
  .blog-date {
    color: #ededed;
    font-size: 1.2em;
    margin-top: 10px;
    font-family: 'Arial, sans-serif';
  }
  .blog-content-container {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    margin-top: 30px;
  }
  
  .blog-content {
    flex: 2;
    padding-right: 20px;
  }
  .blog-content p {
    text-align: justify;
    line-height: 1.6; /* Improved readability with increased line height */
    margin-bottom: 15px; /* Space between paragraphs */
    color: #333; /* Darker text color for better contrast */
    font-family: 'Open Sans', Arial, sans-serif; 
  }
 
  
  
  .blog-content p:first-of-type {
    font-size: 1.1rem; /* Larger font size for the first paragraph */
    font-weight: 500; /* Slightly bold for emphasis */
  }
  
  .blog-content p:last-of-type {
    margin-bottom: 0; /* No bottom margin for the last paragraph */
  }
  
 
  
  .blog-content p::first-letter {
    font-size: 1.8rem; /* Larger drop cap size */
    font-weight: bold; /* Bold for emphasis */
    float: left; /* Float drop cap to the left */
    margin: 0 5px 0 0; /* Margin for spacing */
    line-height: 1; /* Reset line height */
  }
  
  .blog-image-container {
    flex: 1;
    max-width: 400px;
  }
  
  .blog-image {
    width: 100%;
    height: auto;
  }
  .blog-highlight-section {
    background-color: #f9f9f9;
    padding: 20px;
    border-left: 5px solid #ff6600;
    margin-top: 20px;
  }
  
  .blog-quote {
    font-size: 1.5em;
    font-style: italic;
    margin: 0 0 10px 0;
  }
  
  .blog-highlight-content {
    font-size: 1.2em;
  }


  .blog-section-2 {
    padding: 50px 0; /* Adjust padding as needed */
   
  }
  
  .blog-section-2-content {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  
  .blog-section-2-item {
    margin: 20px;
    width: 250px; /* Adjust width based on your design */
    text-align: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow effect */
    transition: transform 0.3s ease-in-out;
  }
  
  .blog-section-2-item:hover {
    transform: translateY(-5px); /* Lift effect on hover */
  }
  
  .blog-section-2-image {
    width: 100%;
    height: auto;
  }
  
  .blog-section-2-text {
    padding: 15px;
  }
  
  .blog-section-2-text h3 {
    font-size: 1.2rem; /* Heading size */
    margin-bottom: 10px;
  }
  
  .blog-section-2-text p {
    font-size: 0.9rem; /* Paragraph size */
    color: #666; /* Text color */
  }
  


  .blog-section-3 {
    background-color: #f9f9f9;
    padding: 20px;
    border-radius: 8px;
   
    overflow: hidden;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Adding a subtle shadow */
    transition: transform 0.3s ease; /* Smooth transition effect */
  }
  
  .blog-section-3:hover {
    transform: translateY(-5px); /* Lifts the section slightly on hover */
  }
  
  .blog-section-3-content {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  
  .blog-section-3-image {
    width: 300px;
    height: auto;
    margin-right: 20px;
    border-radius: 8px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1); /* Adding a subtle shadow to the image */
    transition: transform 0.3s ease; /* Smooth transition effect */
  }
  
  .blog-section-3-image:hover {
    transform: scale(1.05); /* Slightly scales up the image on hover */
  }
  
  .blog-section-3-text {
    flex: 1;
  }
  
  .blog-section-3-text h2 {
    font-size: 2em;
    margin-bottom: 10px;
    color: #333;
  }
  
  .blog-section-3-text p {
    font-size: 1.2em;
    line-height: 1.6;
    color: #666;
  }

  @media (max-width: 768px) {
  .blog-details {
    margin: 15px;
    padding: 10px;
  }

  .blog-title {
    font-size: 1.5em;
  }

  .blog-date {
    font-size: 1em;
  }
  .blog-content-container{
    flex-direction: column; 
  }

  .blog-content {
    padding-right: 0;
  }




  .blog-section-3-content {
    flex-direction: column; 
  }
}